.calendarContainer {
    position: relative;
    height: 50vh;
    margin-top: 5px;
  }
  
  .loaderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 255 255);
    z-index: 10;
    height: 618px;
  }
 
 
 .fc-direction-ltr {
     direction: ltr;
     text-align: left;
     width: 100%;
     padding: 5px;
 }
 
 /* Controle la hauteur et largeur du calendrier */
 .fc-direction-ltr {
     direction: ltr;
     text-align: left;
     width: 100%;
     height: calc(100vh - 13vh);
 }
 
 /* Controle la taille du titre en haut (le mois) */
 .fc .fc-toolbar-title {
    font-size: 13px;
    font-weight: 500;
 }
 
 /* Controle la taille de tout les textes dans le calendrier, header et body*/
 .fc .fc-view-harness-active > .fc-view {
     inset: 0px;
     position: absolute;
     font-size: 12px;
     background-color: #ffffff;
     box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2);
 }
 /* Couleur de fond au survol */
 .fc-daygrid-day:hover {
     background-color: #00b16124; 
     cursor: pointer; 
   }
 
   /* Couleur de fond au survol */
   .fc-timegrid-slot:hover {
     background-color: #00b16124;
     cursor: pointer;
   }
 
   .fc-timegrid-slot-label {
     padding-left: 20px; /* Adjust the value as needed */
   }
 
   /*  Control the color of the day on month view*/
   .fc .fc-daygrid-day.fc-day-today {
     background-color:#00b16124;
 }

 .fc-button {
    border: none !important; /* Enlève la bordure */
    box-shadow: none !important; /* Enlève l'ombre si nécessaire */
    /* Autres styles pour personnaliser l'apparence des boutons */
}
 
   /*  Control the color of the week on month view andh the top right button*/
   :root {
     --fc-button-text-color: #fff;
     --fc-button-bg-color:var(--primary-color);
     --fc-button-border-color:var(--primary-color);
     --fc-button-hover-bg-color:var(--primary-color);
     --fc-button-hover-border-color:var(--primary-color);
     --fc-button-active-bg-color:var(--primary-color);
     --fc-button-active-border-color:var(--primary-color);
     --fc-today-bg-color: #a74fff00;
 }
 
 /* Control the color of the border of all the line in the body */
 .fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #dfdfdf66;
 }

 /* Controler the outer border design */
 .fc .fc-scrollgrid {
    border-collapse: separate;
    border: none;

 }

 /* Toolbar display */
 .fc-toolbar-chunk{
    display: flex;
    align-items: center;
 }

 .calendarContainer .fc-button:focus {
    outline: none;
    border: none;
  }
  
  /* Ajouter un effet de hover pour tous les boutons dans FullCalendar */
  .calendarContainer .fc-button:hover {
    background-color:  var(--primary-color-hover); /* Changez cette couleur selon votre thème */
    cursor: pointer;
  }
 
 /* Padding of the header */
 
 .fc .fc-col-header-cell-cushion {
     display: inline-block;
     padding: 19px 0px;
     font-size: 11px;
     color: rgb(171, 171, 171);
 }

 .fc .fc-daygrid-day-number {
    padding: 4px;
    position: relative;
    z-index: 4;
    color: rgb(171, 171, 171);
}

.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
    padding: 0px 4px;
    color: rgb(171, 171, 171);
}
 
 /* Margin of the header with the calendar + control of the left text*/
 .fc .fc-toolbar.fc-header-toolbar {
     margin-bottom: 10px;
 }
 
 /* Control of the right top buttons */
 
 .fc .fc-button {
  padding: 6px 22px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  background-color: var(--primary-color);
  color: var(--white-text);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
 }

 .fc .fc-button:disabled {
  opacity: 0.80;
}
 
 /* Top Left title */
 .fc .fc-toolbar-title {
     font-size: 14px;
     margin: 0px;
 }
 
 
 /* Augmenter le padding interne des cellules du corps du calendrier */
 .fc .fc-timegrid-slot {
     padding-top: 10px; /* Augmente le padding supérieur */
     padding-bottom: 10px; /* Augmente le padding inférieur */
 }
 
 .fc .fc-timegrid-slot-lane {
     padding-top: 10px;
     padding-bottom: 10px;
 }