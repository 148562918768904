.notificationUser{
    position: absolute;
    width: 20%;
    height: 250px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    right: 35px;
    top: 45px;
    z-index: 9999;
    overflow: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 10px
}

.notificationUserItem{
}

.notificationUserItem{
	text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #eeeeee; 
  }
.notificationUserItem:hover{
    background-color: rgba(235, 235, 235, 0.338);
	

  }

.notificationUserItemBottomTop{
	text-align: left;
  }

.notificationUserItem span{
	font-size: 13px;
  }
.notificationUserItem svg{
	color: #93a1ca;
	padding-right: 10px;
  }

  .capitalize-first {
	text-transform: capitalize;
  }

  .notificationUserItemBottom{
  }

  .notificationUserTitle{
    text-align: left;
    color: #93a1ca;
    font-weight: 400;
    border-bottom: 1px solid #93a1ca;
    width: 33%;
    padding-bottom: 5px;
    margin-left: 10px;
  }

  .userNoNotification{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(234, 234, 234);
    margin-top: 38px;
  }
  .userNoNotification svg{
    font-size: 90px;
  }
  .userNoNotification span{
    font-size: 20px;
  }