:root {
    --primary-color: #00B161; /* Official green of the website */
    --primary-color-hover: #00b161d4; /* Official HOVER green of the website */
    --primary-background: rgb(251 251 251);
    --white-background: white;
    --white-text: white; /* This target all the white text but can also target svg icon */
    --svg-color: white; /* This target all the svg icon on a colorful  */

    /* SIDEBAR  */
    --sidebar-background-color: white;
    --sidebar-primary-color: black;
    --sidebar-secondary-color: #b4b5c0;
    /* SIDEBAR END */
  }
  

  .loaderOverlayAllApp {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: rgba(255, 255, 255, 0.8); 
    z-index: 1000; 
}
