.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(208, 208, 208, 0.21);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  z-index: 9998;
    
}

.popUpImportLeadCsv{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    display: flex;
    width: 75%;
    height: 90vh;
    border-radius: 5px;
    overflow: auto;
    /* border-top: 6px solid var(--primary-color); */
}

.closeIconContainer {
    position: absolute; 
    top: 10px; 
    right: 10px; 
    cursor: pointer; 
    color: var(--primary-color)
  }
  

  .importContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 4;
    justify-content: center; 
  }
  
  .importContainer.afterFileLoad {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 4;
  }

  .importContainer input[type="file"] {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .fileUploadContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .fileUploadButton {
    background-color: var(--primary-color);
    color: var(--white-text);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .fileUploadButton:hover {
    background-color:  var(--primary-color-hover);
  }

  .topImportContainer{
    flex: 1;
  }

  .bottomImportContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .bottomImportContainer.afterFileLoad{
    flex: 10;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }


  .headerMappingContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
    margin-bottom: 20px;
  }

  
  .headerMapping {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .headerMapping span {
    min-width: 80px;
    font-size: 12px;
  }


  
  
  .headerMapping select {
    padding: 5px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #949494;
    font-size: 12px;
}


.tableContainer {
    height: 400px; 
    overflow-y: auto; 
    margin-top: 20px;
  }

  .previewTable {
    width: 100%;
    border-collapse: collapse;
  }
  

  .previewTable thead th {
    background-color: #ffffff;
    color: #000;
    text-align: left;
    padding: 8px;
    font-weight: 400;
    border-bottom: 2px solid #ddd;
    text-align: center;
  }
  
  .previewTable th, .previewTable td {
    /* background-color: var(--white-background); */
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 12px;
    text-align: center;
    max-width: 100px;
  }
  
  .previewTable th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .previewTable tr:nth-child(even) { background-color: #f9f9f9; }
  .previewTable tr:hover { background-color:  var(--primary-color-hover); }
  

 .separatordiv{
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
 }
  .linediv{
    width: 3px;
    height: 50vh;
    background-color: var(--primary-color);
  }

  .rightDivImportCsbLead {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .numberOfleadDisplay {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  
  .numberOfleadDisplay :nth-child(2) {
    font-size: 5rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .customSelect {
    width: 70%;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    background-color: #fefefe;
  }
  
  .selectValue {
    text-align: center;
  }
  
  .optionsContainer {
    display: none;
    position: absolute;
    background-color: var(--white-background);
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    left: 0;
  }
  
  .optionsContainer.show {
    display: block;
  }
  
  .option {
    padding: 10px;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .option:hover {
    background-color: var(--primary-color);
    color: var(--white-text);
  }
  
  .rightDivImportCsbLead button {
    width: 50%;
    margin: 0 auto;
    padding: 15px;
    border: none;
    background-color: var(--primary-color);
    color: var(--white-text);
    cursor: pointer;
  }
  
  .rightDivImportCsbLead button:hover {
    background-color:  var(--primary-color-hover);
    color: var(--white-text);
  }
  
  .duplicate {
    background-color: rgb(255, 238, 242);
  }

  .previewTable tr.duplicate {
    background-color: rgb(255, 238, 242) !important;
}
  

  .error-messageImport {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }