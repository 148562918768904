.overlayPopupCustomer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(208, 208, 208, 0.21);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(3px);
    z-index: 9998;
  }

  .arrowLeft{
    position: absolute;
    top: 50%;
    left: -80px;
    color: grey;
    cursor: pointer;
}
.arrowRight{
    position: absolute;
    top: 50%;
    right: -80px;
    color: grey;
    cursor: pointer;
  }

.popupContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: 80vw;
    height: 85vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}

.closeIconPopupCustomer{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.closeIconPopupCustomer svg{
    font-size: 18px;
    color: rgb(167, 167, 167);
}

.popupWrapper{
    display: flex;
    height: 100%;
    gap: 20px;
}

.popupLeftSide{
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.popupRigthSide{
    flex: 3.5;
    background-color: rgb(245 246 250 / 70%);
    height: 99%;
    margin: auto;
}

