.popupRigthSideBottomLeft{
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .popupRigthSideBottomLeftTop{
    flex: 1;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
  }

  .iconWithTitle{
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #c0c0c0;
    gap: 10px;
    margin-top: 4px;
  }
  .iconWithTitle span{
    font-size: 11px;
  }
  .iconWithTitle svg{
    font-size: 14px;
  }


.userPerfomancePageItem{
  display: flex;
  align-items: center;
  background-color: var(--white-background);
  flex: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 13px;
  border-radius: 5px;
}

.userPerfomancePageItemTop{
  flex: 1;
  color: #c0c0c0;
}
.userPerfomancePageItemTop svg{
  font-size: 27px;
  color: #81cfbd;
}

.userPerfomancePageItemBottom :nth-child(1){
font-size: 11px;
color: #c0c0c0;

}
.userPerfomancePageItemBottom :nth-child(2){
font-size: 16px;
font-weight: 400;
}
.userPerfomancePageItemBottom{
  flex: 3;
  display: flex;
  flex-direction: column;
  color: #7ec5cf;
  font-weight: 300;
  gap: 10px;
}

.statsWrapperPerTwo{
  display: flex;
  width: 100%;
  gap: 10px;
  height: 100%;
}



  .popupRigthSideBottomLeftBottom{
    flex: 2.7;
    display: flex;
    flex-direction: column;
    gap: 15px;
    
  }
  .popupRigthSideBottomLeftBottomLeft{
    flex: 1;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    overflow: auto;
    height: 53vh;
    border-radius: 5px;

  }


  .popupRigthSideBottomLeftBottomRight{
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .popupRigthSideBottomLeftBottomRightTop{
    flex: 1;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

  }

  .popupRigthSideBottomLeftBottomRightBottom{
    flex: 1;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .popupRigthSideBottomRight{
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    flex: 1.6;
    border-radius: 5px;
  }

  
/* STATS */
  .statsWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
  }

  .statsWrapperItem{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .statsWrapperItem :nth-child(1){
      font-size: 12px;
      color: #c0c0c0;
  }

.statsWrapperItem :nth-child(2){
    font-size: 20px;
    font-weight: 400;
    background-clip: text;
    /* color: #e76790; */
    display: inline-block;
}



/* TIMELINE */

.timelineWrapper{
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 15vh;
  overflow: auto;
  border-radius: 5px;

}

.timelineWrapper table {
  border-collapse: collapse;
}

.timelineWrapper thead{
  font-size: 12px;
}
.timelineWrapper thead tr{
  
}
.timelineWrapper thead tr th{
  font-size: 12px;
  color: rgb(171, 171, 171);
  text-align: left;
  padding: 5px;
}
.timelineWrapper tbody{
  text-align: center;
}
.timelineWrapper tbody tr{
  border-bottom: 1px solid #dfdfdf66;
}
.timelineWrapper tbody tr td{
  font-size: 12px;
  text-align: left;
  color: rgb(62, 62, 62);
  font-weight: 400;
  padding: 8px;
}






/* TURNOVER */

.turnoverWrapper{
  padding: 10px;
  margin-top: 20px;
}

.turnoverWrapperItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.turnoverWrapperItemLeft{

}

.turnoverWrapperItemLeftItem{
  background-color: #7ec5cf;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 9px;
  color: var(--white-text);
  font-weight: 300px;
}

.turnoverWrapperItemRight{
  display: flex;
  flex-direction: column;
  width: 125px;
  align-items: flex-end;
}

.turnoverWrapperItemRight :nth-child(1){
  font-size: 20px;
  color:rgb(89, 89, 89)y;
}
.turnoverWrapperItemRight :nth-child(2){
  color: grey;
  font-size: 13px;
}
.turnoverWrapperItemRightBuyingBack{
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: flex-end;
  margin-bottom: 32px;
}

.turnoverWrapperItemRightBuyingBack :nth-child(1){
  font-size: 24px;
  color:rgb(89, 89, 89)y;
}
.turnoverWrapperItemRightBuyingBack :nth-child(2){
  color: grey;
  font-size: 13px;
}

.progressionBar{
  margin-top: 20px;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, #7ec5cf 0%, #81cfbd 100%);
  border-radius: 10px;
}

/* BUYING BACK  */

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
  margin-left: 11px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: linear-gradient(to right, rgba(62,117,200,1) 0%, rgba(76,203,171,1) 100%)!important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.buyingBackWrapper{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}
.buyingBackWrapperLeft{
  width: 100px;
}

.buyingBackWrapperRight{

}