.createTemplateProduct{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9998;
    width: 50%; 
    height: 51%;
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconTemplateProduct svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.createTemplateProductWrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    justify-content: space-between;
}

.topCreateTemplateProduct{
    display: flex;
}
.topCreateTemplateProduct span{
    font-size: 18px !important; 
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}



.middleCreateTemplateProduct {
    display: flex;
    flex-wrap: wrap; 
    align-items: flex-start; 
    gap: 20px; 
    padding: 10px;
    /* border: 1px solid black; */
    height: auto; 
    overflow: auto;
}

.middleCreateTemplateProductItem.selected{
    border: 3px solid var(--primary-color);

}
.middleCreateTemplateProductItem:hover{
   background-color: rgba(248, 248, 248, 0.374);

}
.middleCreateTemplateProductItem {
    flex: 0 1 calc(49% - 10px); 
    min-width: calc(40% - 10px); 
    border: 1px solid #ccc;
    height: 28vh;
    display: flex;
    justify-content: center; 
    align-items: center; 
    position: relative;
    cursor: pointer;
}

.middleCreateTemplateProductItem:first-child {
    border-color: var(--primary-color); 
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.addIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--primary-color);
    width: 50px; 
    height: 50px;
    cursor: pointer;
}
.addIconWrapper svg{
   font-size: 35px;
}

.templatePreview{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    border-left: 1px solid #ccc;
    padding: 5px;
    width: 90%;
}

  .vignetteItem {
    background-color: #f2f2f2; 
    padding: 5px 10px; 
  }

.templateNameVignette {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary-color); 
    color: #ffffff; 
    padding: 5px 10px; 
    margin-bottom: 10px; 
    font-weight: 300; 
    font-size: 12px;
  }

  .isSelectedIndicator{
    width: 20px;
    height: 20px;
    background-color: var(--primary-color);
    border-radius: 50%;
    position: absolute;
    bottom: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .isSelectedIndicator svg{
    color: white;
    font-size: 14px;
  }

  .iconDeleteAndEditTemplate{
    position: absolute;
    top: 3px;
    display: flex;
    right: 10px;
    color: grey;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  .iconDeleteAndEditTemplate svg{
    font-size: 18px;
  }

.bottomCreateTemplateProduct{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomCreateTemplateProduct button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButton {
    background-color: transparent;
    color: grey;
}

.cancelButton:hover {
    color: #e76790;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.bottomCreateTemplateProduct button svg {
    fill: var( --svg-color); 
}
