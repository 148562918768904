.genericForm{
    width: 100%;
}

.datatableGenericForm{
    padding: 10px;
    width: 99%;
    overflow-x: auto;
    border-radius: 5px;
}
.searchBarDataTableGenericForm{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.leftElementsTableGenericForm{
  display: flex;  
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 16px;
}

.leftElementsTableGenericForm span{
    font-size: 13px;
    font-weight: 500;
}

.leftElementsTableGenericForm select{
  font-size: 11px;
  padding: 8px 0px;      
  border: none;
  cursor: pointer;
  border: 1px solid rgb(219, 219, 219);
  color: grey;
  max-width: 109px;
}

.leftElementsTableGenericForm button{
    padding: 6px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
     
    font-weight: 200;
    background-color: var(--primary-color);
    color: var(--white-text);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.leftElementsTableGenericForm button svg{
    font-size: 16px;
}
.leftElementsTableGenericForm button span{
    margin-right: 5px;
    font-size: 11px;
}

.textareaGenericForm .scriptTextarea {
    width: 88%;
    height: 59vh;
    border: 1px solid #f0f0f0;
    font-family: monospace;
    resize: vertical;
  }

  .textareaGenericForm {
    position: relative;
  }
  

  .textareaGenericForm .copyButton {
    position: absolute;
    /* top: 10px; */
    bottom: 30px;
    padding: 6px;
    background-color: var(--primary-color);
    color: var(--white-text);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 11px;
  }
  
  .textareaGenericForm .copyButton1 {
    right: 190px;
  }
  

  .textareaGenericForm .copyButton2 {
    right: 10px; 
  }

  .copyNotification {
    position: fixed;
    left: 50%;
    top: 5%;
    transform: translateY(-50%);
    background-color: var(--white-background);
    color: var(--primary-color);
    padding: 20px 60px;
    border-radius: 0 5px 5px 0;
    animation: fadeOut 2s;
    font-size: 12px;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; }
  }

.buttonCampaignNotSelected{
    background-color: rgb(177, 177, 177) !important;
}

  .noScriptMessage {
    text-align: center;
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-size: 30px; 
    color: #dddddd; 
  }

  .noScriptMessage svg{
    font-size: 50px;
    
  }

  /* Styliser le conteneur du Select */
.react-select__control {
  width: 150px; 
  height: 10px; 
  font-size: 14px; 
  min-height: 35px !important;
  overflow: auto;
}

/* Styliser le placeholder */
.react-select__placeholder {
  color: #999; /* Couleur du placeholder */
  font-size: 12px; /* Taille de la police du placeholder */
}

/* .react-select__multi-value {
  font-size: 14px; 
}

.react-select__menu {
  font-size: 14px; 
} */
