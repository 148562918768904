.createPtemplate{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: 65%; 
    height: 56%;
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 

}

.closeIconPtemplate svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.createPtemplateWrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.topCreatePtemplate{
    display: flex;
}

.topCreatePtemplate span{
    font-size: 18px !important; 
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}

.middleCreatePtemplate{
    display: flex;
    align-items: center;
    /* border: 1px solid black; */
    gap: 10px;
}

.middleCreatePtemplateLeft{
  flex: 1.6;
  border-right: 1px solid rgb(216, 216, 216);
  padding: 10px;
  height: 40vh;
  overflow: auto;
}

.middleCreatePtemplateLeftWrapper{
  /* border: 1px solid black; */
  height: 40vh;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
}

.middleCreatePtemplateLeftWrapperTop{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.middleCreatePtemplateLeftWrapperInputs{
  display: flex;
  gap: 10px;
}
.middleCreatePtemplateLeftWrapperInputs input{
 padding: 7px;
 width: 21%;
 border: 1px solid #ccc;
}

.indexInputTemplateComponent{
  width: 50px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
}

.middleCreatePtemplateLeftWrapperBottom{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.templateTopInputs{
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.templateTopInputs input{
padding-right: 30px;
width: 100%;
}

.templateTopInputs svg{
  position: absolute;
  right: 5px;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 16px;
}

.templateEditor-typeAttributesContainer{
  font-size: 12px; 
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.typeDetails {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}


.indexInputTemplate{
  width: 9%;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #c8c8c8;
}

.addAttributesContainer{
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primary-color);
  cursor: pointer;
}

.vignetteButton{
  border-radius: 5px;
  border: none;
  background-color: white;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 5px;
  font-weight: 300;
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;
}

.vignetteButton:hover, .vignetteButtonActive {
  background-color: var(--primary-color);
  color: var(--white-text);
}

.vignetteButtonActive {
  background-color: var(--primary-color);
  color: white; 
}


.addAttributeInput {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.newAttributeInput {
  width: 100%;
  padding: 7px;
  border: 1px solid #ccc;
}


.addAttributeIcon {
  position: absolute;
  right: 5px;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 16px;
}

.typeDetailsBottomBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.typeDetailsBottom{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.attributeContainer {
  display: flex;
  align-items: center;
  gap: 10px; 
  width: 70%;
}


.attributeInput {
  flex-grow: 1; 
  margin: 5px 0; 
  padding: 4px;
  border: 1px solid #c8c8c8;
  /* border-radius: 5px; */
  background-color: #f9f9f9; 
}

.colorPicker {
  cursor: pointer;
  border: none;
  height: 30px;
  width: 30px;
}

.attributeInput.editing {
  background-color: #ffffff; 
}

.vignetteButtonActive {
  background-color: var(--primary-color); 
  color: white;
}

.editAttributeIcon{
  cursor: pointer;
  color: var(--primary-color);
  font-size: 16px;
}
.closeAttributeIcon {
  cursor: pointer;
  color: red;
  font-size: 16px;
}

.checkAttributeIcon{
  cursor: pointer;
  color: var(--primary-color);
  font-size: 16px;
}

.eyeAttributeIcon{
  cursor: pointer;
  color: grey;
  font-size: 16px;
}



.middleCreatePtemplateRight{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  gap: 10px;
  margin-bottom: 10px;
}

.middleCreatePtemplateRightTop{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.bottomCreatePtemplate{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.bottomCreatePtemplate button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButton {
    background-color: transparent;
    color: grey;
}

.cancelButton:hover {
    color: #e76790;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.bottomCreatePtemplate button svg {
    fill: var( --svg-color); 
}


