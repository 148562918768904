.question-mark {
    margin-left: 5px;
    cursor: help;
  }
  
  .tooltipSidebarRight {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
    top: 10px; 
    left: 0;
    width: max-content;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 10px;
  }