.addFundPopup{
    position: absolute;
    right: 10px;
    font-size: 11px;
    top: 10px;
    color: var(--primary-color);
    cursor: pointer;
}
.addFundPopup:hover{
    color: var(--primary-color-hover);
}