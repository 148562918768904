.popupLeftSideTop{
    flex: 1;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    padding: 10px 0px;
}
.popupLeftSideTop svg{
   font-size: 75px;
   color: #cfcfcf;
}

.ppCustomer{
    position: relative;
}
.ppCustomer img{
    width: 90px;
}

.connectedDot{
    cursor: pointer;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px solid white;
    background-color: #cfcfcf;
    right: 6px;
    top: 4px;
}

.connectedDot.online {
    background-color: var(--primary-color);
  }

.editIconPopUp{
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    cursor: pointer;
}
.editIconPopUp svg{
    font-size: 20px;
}

.popupLeftSideTopName{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px
}
.popupLeftSideTopName span{
   font-size: 18px;
   font-weight: 300;
}
.popupLeftSideTopName svg{
   font-size: 16px;
}


.popupLeftSideBottom{
    flex: 3;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
    overflow-y: auto; 
}

.popupLeftSideBottomFormRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
}

.popupLeftSideBottomFormLabel {
    flex-basis: 40%;
    text-align: left;
    font-size: 12px;
    color: #777;
}

.popupLeftSideBottomFormInput {
    flex-basis: 58%;
    padding: 4px; 
    font-size: 11px; 
    border: none;
    border-bottom: 1px solid rgb(206, 206, 206);
    border-radius: 4px; 
    height: 30px; 
    box-sizing: border-box; 
    width: 100%;
}

.popupLeftSideBottomFormSelect {
    flex-basis: 58%;
    padding: 2px; 
    font-size: 11px; 
    border: none;
    border-bottom: 1px solid rgb(206, 206, 206);
    border-radius: 4px; 
    height: 36px; 
    box-sizing: border-box; 
    width: 100%;
    cursor: pointer;
}

.containerNameAssign{
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    padding: 12px 10px;
    gap: 10px;
    overflow-y: auto; 
    border-top: 0.5px solid rgba(188, 188, 188, 0.565);
    position: relative;
}

.popupLeftSideBottomFormRowImgAndName{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
}

.imgAndNameAssignTo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
    gap: 15px;
    margin-left: 38px;
    max-width: 124px;
}
.imgAndNameAssignTo img{
    width: 30px;
}

.dropdownSelectSales{
    right: 0px;
    position: absolute;
    width: auto;
    height: 175px;
    background-color: var(--white-background);
    bottom: 54px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    border-top: 1px solid rgb(225, 225, 225);
    border-right: 1px solid rgb(225, 225, 225);
    border-left:1px solid rgb(225, 225, 225);
}

.imgAndNameAssignToDropdowm{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 11px;
    gap: 18px;
    cursor: pointer;
    padding: 10px 0px;
}
.imgAndNameAssignToDropdowm img{
    width: 30px;
}

.imgAndNameAssignToDropdowm:hover{
    background-color: rgba(234, 234, 234, 0.218);
}

.isEditingButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    
}
.isEditingValidate{
    padding: 8px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 12px;
    margin-top: 10px;
    background-color: var(--primary-color);
    color: var(--white-text);
}

.isEditingValidate:hover{
    background-color:  var(--primary-color-hover);
}
.isEditingCancel{
    padding: 8px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 12px;
    margin-top: 10px;
    background-color: transparent;
    color: grey;
}

.isEditingCancel:hover{
    color: #e76790;
}