.divColorAccount{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.nameAndStatusAndImg{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameAndStatusAndImg img{
    width: 35px;
    height: 100%;
}
.nameAndStatusAndImg span{
    width: 100%;
    text-align: left;
    margin-left: 20px;
}
