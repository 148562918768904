.dashboardContainerTop{
    /* border: 1px solid black; */
    /* height: 270px; */
    display: flex;
    gap: 15px;
}

.dashboardContainerTopLeft {
    flex: 1.9;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    padding: 27px;
    background-color: var(--white-background);
    border-radius: 10px;
    gap: 25px;
    box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2);
}

.dashboardContainerTopLeftWrapperTop {
    display: flex;
    flex: 1 1;
    /* border: 1px solid black; */
    justify-content: space-between;
    align-items: center;
}

.dashboardContainerTopLeftWrapperTopLeft{
    display: flex;
    align-items: center;
    gap: 20px;
    /* border: 1px solid black; */
}

.dashboardContainerTopLeftWrapperTopLeft img{
    width: 50px;
    border-radius: 50%;
    border: 2px solid #81cfbd;
}

.dashboardContainerTopLeftWrapperTopLeftTitle{
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
}
.dashboardContainerTopLeftWrapperTopLeftTitle :nth-child(1){
   font-size: 26px;
   color: rgb(60, 60, 60);
}
.dashboardContainerTopLeftWrapperTopLeftTitle :nth-child(2){
   font-size: 12px;
   font-weight: 300;
   color: grey;
}

.dashboardContainerTopLeftWrapperTopRight{
    display: flex;
    gap: 10px;
    margin-right: 10px;
}

.dashboardContainerTopLeftWrapperTopRight select{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(175, 175, 175);
}
.dashboardContainerTopLeftWrapperTopRight button{
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--white-text);
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
}
.dashboardContainerTopLeftWrapperTopRight button svg {
    font-size: 18px;
}

@keyframes scrollInfinite {
    0% {
      transform: translateX(0%);
    }
    100% {
      /* Déplacez le conteneur plus que -50% si le contenu est suffisamment dupliqué */
      transform: translateX(-100%);
    }
  }
  
  
  .dashboardContainerTopLeftWrapperBottom {
    flex: 1.3;
    display: flex;
    gap: 65px;
    padding: 8px;
    max-width: 49vw;
    overflow-x: auto; 
    overflow-y: hidden;
  }
  
  .scrollAnimationContainer {
    display: flex;
    animation: scrollInfinite 40s linear infinite;
    gap: 81px;
  }

  .scrollAnimationContainer.paused {
    animation-play-state: paused;
  }
  
  
  .dashboardContainerTopLeftWrapperBottomItem {
    min-width: 200px;
    display: inline-flex;
    height: 155px;
}

.dashboardContainerTopLeftWrapperBottomItemLeft{
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* border: 1px solid black; */
    flex: 1;
    text-align: left;
    margin-top: 20px;
}

.dashboardContainerTopLeftWrapperBottomItemLeft :nth-child(1){
    font-size: 14px;
    color: grey;
}
.dashboardContainerTopLeftWrapperBottomItemLeft :nth-child(2){
    font-size: 16px;
}
.dashboardContainerTopLeftWrapperBottomItemLeft :nth-child(3){
    font-size: 14px;
    color: #81cfbd;
}

.dashboardContainerTopLeftWrapperBottomItemRight{
    flex: 1.5;
    margin-right: 5px;
    /* margin-top: 1px; */
}

/* RIGHT SIDE */
