.country label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-basis: calc(33.333% - 20px);
}

/* .country label select {
    padding: 6px;
    text-align: left;
    border: 1px solid rgb(188, 188, 188);
    width: 100%; 
} */