
.salesPerfomancePageItem{
    display: flex;
    align-items: center;
    background-color: var(--white-background);
    flex: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
}

.salesPerfomancePageItemTop{
    flex: 1;
    color: #c0c0c0;
}
.salesPerfomancePageItemTop svg{
    font-size: 27px;
    color: #81cfbd;
}

.salesPerfomancePageItemBottom :nth-child(1){
font-size: 11px;
color: #c0c0c0;

}
.salesPerfomancePageItemBottom :nth-child(2){
font-size: 16px;
font-weight: 400;
}
.salesPerfomancePageItemBottom{
    flex: 3;
    display: flex;
    flex-direction: column;
    color: #7ec5cf;
    font-weight: 300;
    gap: 10px;
}
