/* .navbarWrapper{
    margin-top: 19px;
} */

.navigationAndFilterRightNoNeed {
    visibility: hidden; 
}

.navigationAndFilterRightNoNeed{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.navigationAndFilterRightNoNeed ul svg{
  font-size: 13px;
  margin-top: 4px;
}

.navigationAndFilterRightNoNeed ul li:hover {
    background-color: rgba(147, 147, 147, 0.1); 
}
.navigationAndFilterRightNoNeed ul li {
    color: rgb(143, 143, 143); 
}

.navigationAndFilterRightNoNeed ul li.clicked {
    background-color: var(--primary-color); 
    padding: 0px; 
    /* box-sizing: border-box;  */
    color: var(--white-text);
}


.champDateContainer{
    display: flex;
    cursor: pointer;
    z-index: 10;
}

.champDate{
    font-size: 10px;
    padding: 8px;
    border: 1px solid rgba(171, 171, 171, 0.524);
    border-radius: 5px;
    color: rgba(171, 171, 171, 0.925);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: 400;
}

.champDate svg{
    font-size: 14px;
}

.dateRangePickerContainer {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 20;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); 
}


.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    display: none;
}
.rdrDateDisplayWrapper {
    background-color: rgb(239, 242, 247);
    display: none;
}

.rdrDateRangePickerWrapper .rdrSelected,
.rdrDateRangePickerWrapper .rdrInRange,
.rdrDateRangePickerWrapper .rdrStartEdge,
.rdrDateRangePickerWrapper .rdrEndEdge {
    background-color: var(--primary-color) !important;
}




.navigationAndFilterRightNoNeed ul{
    list-style-type: none;
    overflow: hidden;
}

.navigationAndFilterRightNoNeed ul li{
    width: 25px;
    height: 22px;
    cursor: pointer;
    float: left;
    font-size: 9px;
    line-height: 22px;
    text-align: center;
    border-radius: 0px;
    border: solid 1px #ebebeb;
    /* border-right: none; */
    background: #fff;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
}
