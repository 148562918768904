.commentPopup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 10px;
    z-index: 9999;
    width: 40%; 
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconStatus svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.commentPopupWrapper{
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.topCommentPopup{
    display: flex;
}
.topCommentPopup span{
    font-size: 25px;
    padding: 10px;
    width: 80%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
    margin-left: 30px;
}



.middleCommentPopup{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.middleCommentPopup textarea{
    width: 90%;
    border-radius: 6px;
    border: 1px solid rgb(178, 178, 178);
    background: #fff;
    margin-bottom: 10px;
}

.planComment{
    text-align: left;
    margin-left: 40px;
}
.planComment span{
    color: rgb(178, 178, 178);
}

.planCommentWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 93%;
}

.planCommentWrapperContainer{
    display: flex;
    align-items: center;
    flex: 3;
    gap: 15px;
}
.planCommentWrapperContainer input{
    margin-top: 10px;
    display: flex;
    padding: 5px;
    font-size: 13px;
    border: 1px solid rgb(178, 178, 178);
}

.datePickerTrigger {
    cursor: pointer;
    padding: 8px;
    border: 1px solid #ccc;
    margin-top: 10px;
    font-size: 13px;
    width: 30%;
  }
  
  .datePickerContainer {
    position: absolute;
    z-index: 100;
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
  }

.commentAreaWrapper {
    height: calc(100% - 50px);
    min-height: 300px;
    overflow: unset;
    margin-top: 20px;
    max-height: 60%;
    padding-right: 10px;
    width: 93%;
}

.commentAreaContainer {
    padding-left: 20px;
    margin-left: 20px;
    position: relative;
    height: 300px; 
    overflow: auto;
}

.singleComment {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: solid 1px #c0c0c0;
    align-items: flex-start;
}

.commentNameAndpic{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}
.commentNameAndpic img{
    width: 35px;
}

.commentAuthor {
    font-family: 'nunitoregulard', arial;
    font-size: 14px;
    color: #2c2c2c;
    font-weight: 600;
    margin-bottom: 4px;
}

.commentDate {
    font-size: 12px;
    color: #cecece;
    font-weight: 400;
    margin-bottom: 8px;
}

.commentText {
    margin-bottom: auto; 
    color: #979797;
    font-size: 12px;
}

.appointmentInComment{
    background-color: #4ccbab;
    padding: 5px;
    font-size: 10px;
    border-radius: 20px;
    margin-top: 1px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--white-text);
    padding: 3px 9px;
    border: none;
}
.appointmentInComment svg{
 font-size: 13px;
}

.commentDelete {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 12px;
}

.deleteLink {
    color: #4ccbab;
    cursor: pointer;
    text-decoration: none;
}





.planCommentButton button {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.planCommentButton button svg{
    font-size: 14px;
}



.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}


.errormessage{
    color: rgb(233, 10, 10);
    font-size: 12px;
}
