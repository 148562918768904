.climbingBoxLoaderPopup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 30vh;
    border-radius: 5px;
    overflow: auto;
}

.bottomClimbingBoxLoaderPopup p{
    color: rgb(158, 158, 158);
    margin-top: 20px;
}