.createFollowPayment{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    /* width: 50%;  */
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconStatus svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.createStatusWrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.topCreateStatus{
    display: flex;
}
.topCreateStatus span{
    font-size: 18px !important; 
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}



.middleCreateFollowPayment{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0px;
    width: 100%;
}

.titleFollowPayment{
    font-size: 14px !important; 
    width: 100%;
    color: rgb(178, 178, 178);
    margin-bottom: 10px;
}
.middleCreateFollowPaymentTop {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 61px;
}

.middleCreateFollowPaymentTopItem{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.middleCreateFollowPaymentTopItem button{ 
   position: absolute;
   top: 55px;
   width: 125px;
   border: none;
   padding: 5px;
   border-radius: 5px;
   cursor: pointer;
}

.followPaymentNumber{
    font-size: 19px;
    font-weight: 400;
    color: #7ec5cf;
}

.followPaymentText{
    font-size: 15px;
    color: grey;
}
.middleCreateFollowPaymentMiddle{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.middleCreateFollowPaymentMiddleWrapper {
    width: 100%;
    display: flex;
    gap: 45px;
    align-items: center;
}
.middleCreateFollowPaymentMiddleWrapper input{
    height: 30px;
    cursor: pointer;
    text-indent: 4px;
    border-radius: 6px;
    border: 1px solid #d7d7d7;
    background: #fff;
    margin-bottom: 10px;
}
.middleCreateFollowPaymentMiddleWrapper select{
    height: 30px;
    cursor: pointer;
    text-indent: 4px;
    border-radius: 6px;
    border: 1px solid #d7d7d7;
    background: #fff;
    margin-bottom: 10px;
    width: 117px;
}
.middleCreateFollowPaymentMiddleWrapper button{
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
}

.addFollowPaymentDeposit {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.addFollowPaymentDeposit:hover {
    background-color: var(--primary-color-hover);
}
.middleCreateFollowPaymentMiddleItem{
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.middleCreateFollowPaymentMiddleItem label{
    line-height: 25px;
    font-size: 12px;
    font-weight: 300;
    color: #777;
}

.middleCreateFollowPaymentBottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
}

.bottomCreateStatus{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomCreateStatus button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButton {
    background-color: transparent;
    color: grey;
}

.cancelButton:hover {
    color: #e76790;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.bottomCreateStatus button svg {
    fill: var( --svg-color); 
}


.buyingBackWrapperFollowPayment{
    width: 100px;
  }

  .datatableHistoryFollowPayment{
    /* -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2); */
    width: 99%;
    overflow-x: auto;
    /* background-color: #fdfdfd; */
    border-radius: 5px;
    height: 15vh;
}