
.createUser{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: auto; 
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconStatus svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.createUserWrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.topCreateUser{
    display: flex;
}
.topCreateUser span{
    font-size: 20px;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}

.profilepictureAdd{
  margin: auto;
}
.profilepictureAddButton{
  padding: 10px 15px;
  border: none;
  color: var(--white-text);
  background-color: var(--primary-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  gap: 5px;
}
.profilepictureAddButton:hover{
  background-color:  var(--primary-color-hover);
}

.profilePictureContainer{
  position: relative;
}

.profilePictureContainer img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.editImagesContainer{
  position: absolute;
  top: 70px;
  right: 0;
  background-color: rgb(225, 225, 225);
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.editImagesContainer svg{
  font-size: 20px;
  color: rgb(128, 128, 128);
}

.editImagesContainer:hover{
  background-color: rgba(225, 225, 225, 0.895);
}

.middleCreateUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .inputRow {
    width: 100%; 
  }
  
  .inputRow {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .middleCreateUser span{
    color: grey;
    font-size: 10px;
  }
  
  
  .inputGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .inputGroup input {
    padding: 5px;
    border: none;
  }
  .inputGroup input {
    width: 94%; 
    padding: 4px;
    border: none;
    border-bottom: 1px solid rgb(207, 207, 207);
  }

  .fullWidthInput {
    width: 100%; 
  }

  .fullWidthInput input {
    width: 100%; 
    padding: 4px;
    border: none;
    border-bottom: 1px solid rgb(207, 207, 207);
  }

  .fullWidthInput label{
      font-size: 12px;
    display: flex;
    margin-left: 5px;
    color: grey;
    margin-bottom: 7px;
    }

    .fullWidthInput input[type="color"] {
      width: 24px; /* Taille plus petite */
      height: 24px; /* Forme ronde */
      border-radius: 50%; /* Arrondir les coins */
      border: none;
      padding: 0;
      margin-left: auto; /* Positionner à droite */
      cursor: pointer;
    }
  
  .fullWidthInputColor {
    width: 100%; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  }


  .fullWidthInputColor label{
      font-size: 12px;
    display: flex;
    margin-left: 5px;
    color: grey;
    margin-bottom: 7px;
    margin-top: 5px;
    }

    .fullWidthInputColor input[type="color"] {
      width: 24px; 
      height: 24px; 
      border-radius: 50%; 
      border: none;
      padding: 0;
      cursor: pointer;
    }
  

  .errormessage {
    color: red;
    font-size: 12px;
    align-self: flex-start;
  }
  

.bottomCreateUser{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomCreateUser button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButton {
    background-color: transparent;
    color: grey;
}

.cancelButton:hover {
    color: #e76790;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.bottomCreateUser button svg {
    fill: var( --svg-color); 
    font-size: 16px;
}

.errormessage{
    color: rgb(233, 10, 10);
    font-size: 12px;
}
