.assignSingleLead{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: auto; 
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconStatus svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.assignSingleLeadWrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.topAssignSingleLead{
    display: flex;
}
.topAssignSingleLead span{
    font-size: 20px;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}



.middleAssignSingleLead{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.wrapperAssignSingleLead{
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.wrapperAssignSingleLead label{
    font-size: 13px;
    font-weight: 500;
}
.wrapperAssignSingleLead select{
    padding: 5px;
    border: 1px solid rgb(180, 180, 180);
    cursor: pointer;
}

.bottomAssignSingleLead{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomAssignSingleLead button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButton {
    background-color: transparent;
    color: grey;
}

.cancelButton:hover {
    color: #e76790;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.bottomAssignSingleLead button svg {
    fill: var( --svg-color); 
    font-size: 16px;
}

.errormessage{
    color: rgb(233, 10, 10);
    font-size: 12px;
}
