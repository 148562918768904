.star{
	color: #fea140;
	text-align: center;
	margin-left: -8px;
}

.space-star{
    
}
.star span{
	margin: 0px -8px 0px 0px;
	font-size: 26px;
	font-variation-settings: 'FILL' 1 ;
}


@media screen and (max-width: 1250px) {
	
	

	}

@media screen and (max-width: 1000px) {


	.widgets{
		bottom: 111px !important;
		
	}
	
	.bottombar{
	display: inline-flex !important;
}
	
	
	}

@media screen and (max-height: 700px) {
	
	.widgets {
		height: 139px !important;
		overflow: auto !important;
	}
	
	.widget-tiers{
		
		height: 100% !important;
	}
	
	.widget-deux-tiers{
		
		height: 100% !important;
	
}
	
	
	}

@media screen and (max-width: 1000px) and (max-height: 700px) {
	

	
	
}

@media screen and (max-width: 620px) {
	
.wid-margin-right{
	margin-right: 0px !important;
}
.wid-margin-bottom{
	margin-bottom: 5px !important;
}

.widget-tiers {
	margin-left: 5px !important;
	margin-bottom: 5px !important;
	width: calc((100% / 2) - (10px / 2)) 
}
	
.widget-deux-tiers{

	width: 100% !important;
	margin-left: 5px !important;

	
}

.widgets {
		
	overflow: auto !important;
}
	
.mainprice{
	display: none !important;
}

.h1-select-title span{
	display: inline !important;
}
	
	}


@media screen and (max-width: 499px) {	
	



	
}

@media screen and (max-height: 450px) and (max-width: 1000px) {	
	
	.chart {
		display: none !important;
	}
	.widgets{
		
		height: auto !important;
		top: 88px !important;
		bottom: 101px !important;
	}



}

