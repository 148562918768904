.singleUserPerfomanceContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: 65vw;
    height: 60vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}

.singleUserPerfomanceWrapper{
    display: flex;
    height: 100%;
    gap: 20px;
}

.singleUserPerfomanceLeftSide{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.singleUserPerfomanceRightSide{
    flex: 3.5;
    background-color: rgb(245 246 250 / 70%);
    height: 95%;
    margin: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.singleUserPerfomanceRightSideWrapperTop{

}

.singleUserPerfomanceRightSideTopTile{
    text-align: left;
    color: rgb(178, 178, 178);
    margin-bottom: 14px;
    font-size: 14px;
}


.singleUserPerfomanceRightSideTopWidget{
    display: flex;
    gap: 15px;
}



.singleUserPerfomanceRightSideWrapperBottom{
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
}

.singleUserPerfomanceRightSideWrapperBottomTop{
    display: flex;
    gap: 15px;
    height: 100%;
}

.singleUserPerfomanceRightSideWrapperBottomBottom{
    display: flex;
    gap: 15px;
    height: 100%;
}

.singleUserPerfomanceRightSideWrapperBottomItem{
    display: flex;
    align-items: center;
    background-color: var(--white-background);
    flex: 1 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
}


.singleUserPerfomancePageItem{
    display: flex;
    align-items: center;
    background-color: var(--white-background);
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    height: 100%;
}

.singleUserPerfomancePageItemTop{
    flex: 1;
    color: #c0c0c0;
}
.singleUserPerfomancePageItemTop svg{
    font-size: 35px;
    color: #81cfbd;
}

.singleUserPerfomancePageItemBottom :nth-child(1){
    font-size: 14px;
    color: #c0c0c0;

}
.singleUserPerfomancePageItemBottom :nth-child(2){
    font-size: 24px;
    font-weight: 400;
}
.singleUserPerfomancePageItemBottom{
    flex: 3;
    display: flex;
    flex-direction: column;
    color: #7ec5cf;
    font-weight: 300;
    gap: 10px;
}
