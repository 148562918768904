.salesPerfomancePageBottomLeftTopItemActivity{
  display: flex;
  align-items: center;
  color: #c0c0c0;
  gap: 5px;
  background-color: var(--white-background);
  margin-top: 15px;
  margin-left: 10px;
}
.salesPerfomancePageBottomLeftTopItemActivity span{
  font-size: 11px;
}
.salesPerfomancePageBottomLeftTopItemActivity svg{
  font-size: 14px;
}


.activityTableWrapper{
  padding: 5px;
}

.salesAgentImg{
    display: flex;
    align-items: center;
    gap: 10px;
}

.salesAgentImg img{
    width: 25px;
}

.headerWithIcon {
    display: flex;
    align-items: center;
    justify-content: flex-start;  
    font-size: 11px;
  }

.headerWithIcon svg {
    margin-left: 5px; 
    font-size: 16px;
}

.tableDataSales{
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
  }
  
  .tableDataSales thead {
    /* border: 1px solid #dfdfdf66; */
    font-size: 12px;
  }

  .tableDataSales th {
    padding: 20px;
    color: rgb(171, 171, 171);
    cursor: pointer;
  }
  

  .tableDataSales tbody {
    text-align: center;
    /* border: 1px solid #dfdfdf66; */
  }

.tableDataSales tbody tr {
    /* border: 1px solid #dfdfdf66; */
  }


.tableDataSales td:hover {
    background-color: #00b1611b;
    cursor: pointer;
  }

.tableDataSales tbody tr td  {
    padding: 20px;
    font-size: 10px;
    text-align: left;
    color: rgb(62, 62, 62);
    font-weight: 400;
  } 

  .tableDataSales tbody tr td select {
    padding: 4px;
    border: 1px solid var(--primary-color) ;
    font-size: 10px;
    cursor: pointer;
    text-align: left;
    border-radius: 5px;
    color: rgb(62, 62, 62);
    } 

    .tableDataSales tbody tr.highlighted {
        background-color: #00b1611f;

      }
      
      .tableDataSales tbody tr:hover {
        background-color: #00b16116;

      }
      
      .tableDataSales tbody tr:hover td:first-child {
        background-color: transparent;
      }
      
    
      .tableDataSales tbody tr:hover td:first-child .container input {
        background-color: transparent;
      }
    
      .tableDataSales tbody tr.highlighted td {
        background-color: #00b1610b;
      }

      