.safe-listQuery {
    display: flex;
    flex-direction: column;
    width: 100%;
  }


  .item-safeQuery {
    margin: 0 30px;
    border-top: 1px solid #0000000a;
    padding: 20px 0;
    margin-top: 20px;
    /* margin-right: 14px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 93%;
}

.asset-ti-safeQuery{
	font-size: 14px;
    font-weight: 500;
    display: block;
	height: 20px;
	line-height: 20px;
	float: left;
}
.asset-ti-safeQuery span {
	font-size: 10px;
    font-weight: 500;
	vertical-align: text-top;
	color: #93a1ca;
	cursor: pointer;
	line-height: 10px;
}

.price-asset-safeQuery{
	font-size: 13px;
    font-weight: 300;
    display: block;
    color: #000;
	float: right;
	height: 20px;
	line-height: 20px;
}

.item-safe-detailsQuery{
	height: 15px;
	margin-top: 5px;
}


.item-actions-detailsQuery{
	height: 25px;
	margin-top: 10px;
}

.actionQuery{
	color: #93a1ca;
    text-decoration: underline;
    font-size: 13px;
    font-weight: 300;
    display: block;
	float: left;
	line-height: 16px;
	height: 16px;
	margin-right: 4%;
	cursor: pointer;
}
.actionQuery:hover{
	color: #294598;
}

.icoresizeQuery{
	font-size: 13px !important;
	color: #93a1ca;
	vertical-align: sub;
	margin-right: 2px;
}

.offerssubQuery{
	font-size: 10px;
	color: #df525e;
	font-weight: 500;
	vertical-align: text-top;
	line-height: 10px;
	float: left;
	margin-left: -4%;
	padding-left: 2px;
}


.titleQueryMobile{
width: 100%;
text-align: left;
    margin-left: 59px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    padding: 0px;
    cursor: pointer;
    color: #000;
}