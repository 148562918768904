.calendarContainerDashboard{
    padding: 10px 10px;
    margin-top: 5px;
    position: relative;
}

.react-calendar {
    width: 100% !important;
    max-width: 100% !important;
    background: white !important;
    border: 0px solid #ffffff !important;
    font-family: Arial, Helvetica, sans-serif !important;
    line-height: 1.125em;

}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.733em;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
    display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
    display: none;
}

.react-calendar__navigation__label {
    pointer-events: none; /* Empêche les événements de clic */
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: 300;
    color: #b2bbc0;
}

.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
    border-bottom: none !important;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
    color: #636871;
}

.react-calendar__month-view__days__day--weekend {
    color: #636871 !important;
}

.react-calendar__tile--active {
    background: #81cfbd !important;
    color: var(--white-text);
}

.react-calendar__tile--now {
    background: #81cfbd49 !important;
    color: #636871 !important;
} 


.calendar-navigation {
    display: flex;
    justify-content: space-between; 
    margin-bottom: 10px;
    /* margin-top: 10px;  */
  }
  
  .calendar-navigation span {
    margin: 0 20px;
    font-size: 16px;
  }
  
  .calendar-navigationRight{
    cursor: pointer;
    margin-right: 10px;
  }
  .calendar-navigationRight svg{
    cursor: pointer;
    font-size: 18px;    
  }
  
.legendColorContainerWrapper{
    display: flex;
    gap: 25px;
}
  .legendColorContainer{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: grey;
  }

  .legendColor1{
    width: 10px;
    height: 10px;
    background-color: #81cfbd;
    border-radius: 50%;
  }
  .legendColor2{
    width: 10px;
    height: 10px;
    background-color: #cfab7e;
    border-radius: 50%;
  }
  .legendColor3{
    width: 10px;
    height: 10px;
    background-color: #f3b8b8;
    border-radius: 50%;
  }
  .legendColor4{
    width: 10px;
    height: 10px;
    background-color: rgb(144, 84, 143);
    border-radius: 50%;
  }

  .overlayCalendar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(220, 220, 220, 0.21);
    z-index: 1; /* Assurez-vous que cela est derrière le pop-up mais au-dessus du contenu du calendrier */
  }

  .blurEffect {
    filter: blur(5px);
  }
  
  .popupEventCalendar {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.944);
    width: 45%;
    height: 95%;
    margin-top: 5px;
    top: 0;
    z-index: 2;
}

.popupInnerEventCalendar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popupInnerEventCalendarTop {
  background-color: #fdfdfd;
  flex: none;
  text-align: left;
  padding: 16px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.popupInnerEventCalendarMiddle {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.popupInnerEventCalendarBottom {
  background-color: #fdfdfd;
  flex: none;
  padding: 12px;
  text-align: right;
}
.popupInnerEventCalendarBottom button{
  cursor: pointer;
  padding: 10px 25px;
  border: none;
  background-color: var(--primary-color);
  color: var(--white-text);
}
.popupInnerEventCalendarBottom button:hover{

  background-color: var(--primary-color-hover);

}

.eventItem {
  display: flex;
  align-items: center;
}

.eventItem img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
}

.eventItemInfo {
  flex-grow: 1;
}

.eventItemName, .eventItemDetails {
  margin: 0;
  padding: 0;
}

.eventItemName {
  font-size: 14px;
  color: #333;
}

.eventItemDetails {
  font-size: 12px;
  color: #666;
}

.closeButton {
  background-color: #ff5757;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}