.loginPage{
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    position: relative;
    color: #fff;
    text-align: center;
    /* padding: 30px; */
    /* background: #f5f6f9 url(/crm/images/background.jpg) no-repeat scroll bottom center / cover!important; */
}

.loginPageContainer{
    width: 100%;
    max-width: 433px;
    margin: auto;
    padding-bottom: 50px;
    background-color: var(--white-background);
    padding: 52px;
    border-radius: 14px;
}
.loginPageContainer img{
    width: 450px;
    max-width: 100%;
    padding: 0px;
    margin: 0px 0 8px;
}

.loginPageContainer p {
    font-size: 15px;
    color: black;
}

.formLogin{
    margin-top: 60px;
}

.zonechamp {
    overflow: hidden;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
}

.loginPage input {
    width: calc(50% - 41px);
    height: 36px;
    margin-right: 10px;
    float: left;
    color: #000000;
    font-size: 14px;
    text-align: left;
    text-indent: 0;
    font-weight: 300;
    padding: 6px 10px;
    border: 1px solid rgba(170, 170, 170, 0.73);
    border-radius: 0px;
    opacity: 1;
    background: none;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
}

.loginPage .submit {
    width: 62px;
    height: 42px;
    line-height: 28px;
    color: #fff;
    margin: 0;
    cursor: pointer;
    border: none;
    border-radius: 0px;
    background: #333;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
}

.contentlogin p {
    font-size: 15px;
}

.loginPage footer{
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0px;
    bottom: 30px;
    color: black;
}