.historiquePayment{
  position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 0px;
    z-index: 9999;
    width: auto; 
    max-width: 90%; 
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}



.datatableHistorique{
    padding: 10px;
    /* box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47); */
    /* width: 99%; */
    overflow-x: auto;
    background-color: #fdfdfd;
    height: 25vh;
    width: 600px;
    border-radius: 20px;
    margin-top: 20px;
}




.tableDataHistorique{
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableDataHistorique thead {
    border: 1px solid #dfdfdf;
    font-size: 11px;
  }
  
  .tableDataHistorique th {
    padding: 10px;
    font-weight: 500;
  }
  

  .tableDataHistorique tbody {
      text-align: center;
      border: 1px solid #dfdfdf;
    }
  
  .tableDataHistorique tbody tr {
      border: 0.5px solid #dfdfdf;
    }


    .tableDataHistorique tbody tr td  {
        padding: 15px;
        font-size: 11px;
      }
 
      
      .tableDataHistorique tbody tr:hover {
        background-color: #ffc78c2e;
      }
      
      .tableDataHistorique tbody tr:hover td:first-child {
        background-color: transparent;
      }
      
    
      .tableDataHistorique tbody tr:hover td:first-child .container input {
        background-color: transparent;
      }
    
      .payAmountHistorique{
        margin-top: 20px;
        padding: 10px;
        color: rgb(82, 82, 82);
      }

      .progress_bar_tableHistorique {
        width: 97%; 
        /* max-width: 80px; */
        height: 12px; 
        background-color: #6593e64a;
        border-radius: 10px; 
        margin-top: 10px;
      }
      
      .progress_tableHistorique {
        height: 100%; 
        background-color: #ffc78ccf; 
        border-radius: 10px; 
        transition: width 0.3s ease; 
      }
      
      .progressBarContainer{
        display: flex;
        width: 100%;
        justify-content: center;
      }