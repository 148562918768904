
.sidebar-left{
	position: absolute;
	top: 61px;
	background: #94a2cb05;
	width: 285px;
	bottom: 0px;
	left: 0px;
}

.title-section-sidebarLeft{
    position: relative;
    height: 74px;
    line-height: 1;
    padding-left: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
}

.titles{
	font-size: 16px;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
    display: block;
    float: left;
    margin-right: 20px;
}

.filter{
	color: #94a2cb;
    position: absolute;
    right: 30px;
    top: 26px;
	cursor: pointer;
}
.filter:hover{
	color: #294598;
}

.marketplace-list{
	position: absolute;
	left: 0px;
	top: 74px;
	bottom: 0px;
	right: 0px;
	overflow: auto;
	
}

.asset{
	width: 100%;
	border-top: 1px solid #0000000a;
	display: block;
	cursor: pointer;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: left;
}
.asset:hover{
	background: #00000003;
}

.asset-ti{
	font-size: 14px;
    font-weight: 500;
    text-indent: 30px;
    display: block;
}

.asset-ti-safe{
	font-size: 14px;
    font-weight: 500;
    display: block;
	height: 20px;
	line-height: 20px;
	float: left;
}
.asset-ti-safe a {
	font-size: 10px;
    font-weight: 500;
	vertical-align: text-top;
	color: #93a1ca;
	cursor: pointer;
	line-height: 10px;
}

.asset-ti-offer{
	font-size: 14px;
    font-weight: 500;
    display: block;
	height: 20px;
	line-height: 20px;
	float: left;
}
.asset-ti-offer a {
	font-size: 10px;
    font-weight: 500;
	vertical-align: text-top;
	color: #93a1ca;
	cursor: pointer;
	line-height: 10px;
}

.asset-de{
	font-size: 13px;
    font-weight: 300;
    text-indent: 30px;
    display: block;
	color: #93a1ca;
	margin-top: 2px;
}

.asset-cnt-price{
	height: 26px;
    padding-left: 30px;
    padding-right: 30px;
}

.asset-chip{
	font-size: 9px;
    display: block;
	margin-top: 6px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	width: 30%;
	font-weight: 400;
	text-transform: uppercase;
	float: left;
	border-radius: 2px;
}


.asset-chip3{
	font-size: 9px;
		display: block;
		height: 20px;
		line-height: 20px;
		text-align: center;
		width: 22%;
		font-weight: 400;
		text-transform: uppercase;
		float: right;
		margin-left: 4%;
		border-radius: 2px;
	}

	.asset-chip-color1{
		background: #9b9b9b29;
		color: #9b9b9b;
	}
	.asset-chip-color2{
		background: #8391fb29;
		color: #8391fb;
	}
	.asset-chip-color3{
		background: #c475da29;
		color: #c475da;
	}
	.asset-chip-color4{
		background: #44a9d329;
		color: #44a9d3;
	}
	.asset-chip-color5{
		background: #fea14029;
		color: #fea140;
	}

	.asset-percent{
		font-size: 13px;
		display: block;
		margin-top: 6px;
		height: 20px;
		line-height: 20px;
		font-weight: 300;
		float: left;
		width: 30%;
		text-align: right;
	}
	.asset-percent2{
		font-size: 13px;
		display: block;
		height: 20px;
		line-height: 20px;
		font-weight: 300;
		float: left;
		text-align: center;
		margin-left: 5%;
	}

	.asset-price-color-up{
		color: #0e8b6e;
	}
	.asset-price-color-down{
		color: #df525e;
	}

	.asset-price{
		font-size: 13px;
		display: block;
		margin-top: 6px;
		height: 20px;
		line-height: 20px;
		text-align: right;
		font-weight: 300;
		float: left;
		width: 40%;
	}





	.trend-size-color-down{
		font-size: 14px !important;
		color: #df525e;
		vertical-align: sub;
		margin-right: 2px;
	}

	
	.trend-size-color-up{
		font-size: 14px !important;
		color: #0e8b6e;
		vertical-align: sub;
		margin-right: 2px;
	}

	.trend-size-color-up2{
		font-size: 30px !important;
		color: #0e8b6e;
		vertical-align: middle;
		margin-right: 8px;
	}

	.trend-size-color-down2{
		font-size: 30px !important;
		color: #df525e;
		vertical-align: middle;
		margin-right: 8px;
	}

	.filterSection{
		position: absolute;
		width: 230px;
		height: 180px;
		background-color: #fbfbfb;
		top: 50px;
		right: 30px;
		z-index: 9999;
		border-radius: 5px;
		overflow: auto;
		padding: 10px;
	}

	.filterSection select,
.filterSection input[type="number"],
.filterSection input[type="text"],
.filterSection input[type="range"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.filterSection label {
    margin-bottom: 5px;
    display: block;
    color: #333;
}

.filterSection .priceRangeFilter {
    margin-top: 10px;
}

.filterSection button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.filterSection button:hover {
    background-color: #0056b3;
}


.filterContainer{
	width: 13vw;
	overflow: auto;
}
.filterSelected{
	font-size: 9px;
	display: flex;
	gap: 5px;
	overflow: auto;
	width: 20vw;
}

.filterBadge{
 background-color: #94a2cb;
 padding: 5px;
 color: white;
 border-radius: 5px;
}

.removeFilter {
	margin-left: 8px;
	cursor: pointer;
	color: white;
  }
  
  .removeFilter:hover {
	color: #666;
  }