.assignAllLead{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: 25%; 
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconStatus svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.assignAllLeadWrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.topAssignAllLead{
    display: flex;
}
.topAssignAllLead span{
    font-size: 20px;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}

.numberLeadToAssign{
    color: rgb(178, 178, 178);
    font-size: 13px;
}


.middleAssignAllLead{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
    max-height: 50vh ;
    overflow: auto;
}

.wrapperAssignAllLead{
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.wrapperAssignAllLeadSelect{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow-y: auto; /* Permet de défiler verticalement */
    /* max-height: 30vh; Hauteur maximale avant de commencer à défiler */
    padding: 10px;
}

.assignAllLeadItem{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.assignAllLeadItem select{
    padding: 5px;
    border: 1px solid rgb(180, 180, 180);
    cursor: pointer;
    width: 40%;
}
.assignAllLeadItemStatus{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.assignAllLeadItemStatus label{
    font-size: 12px;
    color: rgb(178, 178, 178);
}

.assignAllLeadItemStatus select{
    padding: 5px;
    border: 1px solid rgb(180, 180, 180);
    cursor: pointer;
    width: 44%;
}

.assignAllLeadItem input{
    padding: 5px;
    border: 1px solid rgb(180, 180, 180);
    cursor: pointer;
    width: 20%;
}

.addSalesAgent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    cursor: pointer;
    color: var(--primary-color);
}

.addSalesAgent:hover{
    color: #018d4e;
}

.addSalesAgent svg{
    font-size: 14px;
}

.addSalesAgent span{
    font-size: 12px;
}

.randomizeLeads{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.randomizeLeads span{
    color: rgb(178, 178, 178);
}

.randomizeLeads input{
    cursor: pointer;
    margin-top: 5px;
}

.bottomAssignAllLead{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
    background-color: var(--white-background);
}

.bottomAssignAllLead button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButton {
    background-color: transparent;
    color: grey;
}

.cancelButton:hover {
    color: #e76790;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.bottomAssignAllLead button svg {
    fill: var( --svg-color); 
    font-size: 16px;
}

.errormessage{
    color: rgb(233, 10, 10);
    font-size: 12px;
}
