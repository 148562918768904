.homepageCampaignDashboardWrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 38vh;
    overflow: auto;
}

.homepageCampaignBottomWrapper{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.homepageCampaignBottomWrapperItem{
    border-left: 4px solid #6cbba9;
    padding: 5px;
}


.homepageCampaignBottomWrapperItemTop span{
    font-size: 12px;
}

.homepageCampaignBottomWrapperItemBottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.homepageCampaignBottomWrapperItemBottom span{
    color: rgb(182, 182, 182);
    font-size: 11px;
}

.homepageCampaignBottomWrapperItemTopAndBottom{
    text-align: left;
    margin-left: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}