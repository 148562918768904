.dashboardContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* height: 50vh; */
}



/* BOTTOM */

.dashboardContainerBottom{
    /* border: 1px solid black; */
    height: 358px;
    display: flex;
    gap: 15px;
}

.dashboardContainerBottomItemLeft{
    flex: 1;
    background-color: var(--white-background);
    box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2);
}
.dashboardContainerBottomItemRight{
    flex: 0.7;
    background-color: var(--white-background);
    box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2);
}
/* WIDGET */


/* END OF WIDGET */

.dashboardContainerBottomItemTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.dashboardContainerBottomItemTitleLeft{
    text-align: left;
    font-size: 12px;
    color: rgb(179, 179, 179);
    display: flex;
    align-items: center;
    gap: 10px;
}
.dashboardContainerBottomItemTitleLeft svg{
    font-size: 18px;
}

.dashboardContainerBottomItemTitleRight{
    display: flex;
    gap: 10px;
}

.dashboardContainerBottomItemTitleRight select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgb(175, 175, 175);
    font-size: 10px;
    cursor: pointer;
}

.dashboardContainerBottomItemTitleRight button {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--white-text);
    padding: 0px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    font-size: 10px;
    cursor: pointer;
}



.dashboardContainerLast {
    height: 358px;
    display: flex;
    gap: 15px;
}
