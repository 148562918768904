.containerPlatform{
    height: 50vh;
  }
  
    .platformWrapper{
        padding: 17px 10px;
        width: 99%;
        overflow-x: auto;
        border-radius: 5px;
    }

    .searchBarPlatform{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      
      .leftElementsTablePlatform{
        display: flex;  
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
     
      .leftElementsTablePlatform span{
          font-size: 13px;
          font-weight: 500;
      }

   .buttonConfirmPlatform {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
   }
   .buttonConfirmPlatform button{
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: var(--primary-color);
    color: var(--white-text);
   }
   .buttonConfirmPlatform button svg{
    fill: var( --svg-color); 
    font-size: 16px;
   }
   .buttonConfirmPlatform button:hover{
    background-color: #00b161d7;
   }