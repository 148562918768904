.catalog-payments-progress {
    width: 100%;
    background-color: #d7d7d7;
    height: 8px;
    position: relative;
  }
  
  .catalog-payments-progress-filled {
    height: 100%;
  }


  /* const calculateTotalPaid = (onHoldEntries) => {
    return onHoldEntries.reduce((total, entry) => {
      // Assurez-vous que entry.amountPay est un tableau de paiements
      const amountPaidForProduct = entry.amountPay.reduce(
        (sum, payment) => sum + payment.amount,
        0
      );
      return total + amountPaidForProduct;
    }, 0);
  };

  useEffect(() => {
    animateValue(animatedBalance, currentData.balance, setAnimatedBalance);
    const totalPaid = calculateTotalPaid(currentData.onHold);
    const newPercentage = (totalPaid / selectedData.product.prix) * 100;

    animateValue(animatedPaidAmount, totalPaid, setAnimatedPaidAmount);
    animateValue(animatedPercentage, newPercentage, setAnimatedPercentage);
  }, [currentData]); */
