.containerDatatable{
    /* padding: 0px 20px; */
    height: 50vh;
  }
  .datatable{
    /* padding: 20px; */
    padding: 10px;
    /* -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2); */
    width: 99%;
    overflow-x: auto;
    /* background-color: #fdfdfd; */
    border-radius: 5px;
}

.tableDataProfileInfo{
    width: 100%;
    border-collapse: collapse;
    background-color: #fdfdfd;
    -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2);
  }
  
  .tableDataProfileInfo thead {
    border: 1px solid #dfdfdf66;
    font-size: 12px;
  }

  .tableDataProfileInfo th {
    padding: 20px;
    color: rgb(171, 171, 171);
    cursor: pointer;
  }

  .tableDataProfileInfo tbody {
    text-align: center;
    border: 1px solid #dfdfdf66;
  }

.tableDataProfileInfo tbody tr {
    border: 1px solid #dfdfdf66;
  }


.tableDataProfileInfo td:hover {
    background-color: #b1bbdb35;
    cursor: pointer;
  }

.tableDataProfileInfo tbody tr td  {
    padding: 14px;
    font-size: 10px;
    text-align: left;
    color: rgb(62, 62, 62);
    font-weight: 400;
  } 

  .tableDataProfileInfo tbody tr td select {
    padding: 4px;
    border: 1px solid var(--primary-color) ;
    font-size: 10px;
    cursor: pointer;
    text-align: left;
    border-radius: 5px;
    color: rgb(62, 62, 62);
    } 

    .tableDataProfileInfo tbody tr.highlighted {
        background-color: #00b1611f;

      }
      
      .tableDataProfileInfo tbody tr:hover {
        background-color: #b1bbdb35;

      }
      
      .tableDataProfileInfo tbody tr:hover td:first-child {
        background-color: transparent;
      }
      
    
      .tableDataProfileInfo tbody tr:hover td:first-child .container input {
        background-color: transparent;
      }
    
      .tableDataProfileInfo tbody tr.highlighted td {
        background-color: #00b1610b;
      }

      

      .catalog-payments-progressPlatform {
        width: 100%;
        background-color: #d7d7d7;
        height: 8px;
        position: relative;  /* Ensures .hover-info is positioned relative to this container */
        cursor: pointer;
    }
    
    .catalog-payments-progress-filledPlatform {
        height: 100%;
        transition: width 0.4s ease; /* Smooth transition for the filling effect */
        background-color: #93a1ca; /* Ensuring the color is set here for consistency */
    }
    
    .catalog-payments-progressPlatform:hover .hover-info {
        display: block; /* Shows the tooltip on hover */
    }
    
    .hover-info {
        display: none; 
        position: absolute;
        bottom: 110%; 
        left: 50%;
        transform: translateX(-50%);
        background-color: #eff0f5;
        color: rgb(0, 0, 0);
        border: 1px solid #cccccc;
        padding: 5px 10px;
        border-radius: 5px;
        white-space: nowrap;
        box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
        z-index: 1000; 
    }
    