.notification-popupTest {
  position: fixed;
  left: 20px;
  padding: 20px;
  background-color: #fbfbfb;
  color: #9d9d9d;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  top: 20px;
}

.notification-popupTest.show {
  transform: translateX(0);
  opacity: 1;
}

.notification-popupTest.hide {
  transform: translateX(-100%);
  opacity: 0;
}
.notification-popupTest span{
	font-size: 13px;
  }
.notification-popupTest svg{
	color: #93a1ca;
	padding-right: 10px;
  }
  
  .notification-popupTestBottomTop{
	text-align: left;
  }

  .capitalize-first {
	text-transform: capitalize;
  }

  @keyframes slideIn {
	from {
	  transform: translateX(-100%);
	  opacity: 0;
	}
	to {
	  transform: translateX(0);
	  opacity: 1;
	}
  }
  
  @keyframes fadeOut {
	from {
	  opacity: 1;
	}
	to {
	  opacity: 0;
	}
  }

  
  @keyframes slideOut {
	from {
	  transform: translateX(0);
	  opacity: 1;
	}
	to {
	  transform: translateX(-100%);
	  opacity: 0;
	}
  }

  
  .fade-out {
	animation: slideOut 0.5s ease-out forwards;
  }