
.sidebar {
    flex: 1.3;
    height: 100vh; /* Pleine hauteur */
    overflow-y: auto; /* Scroll uniquement si nécessaire */
    background-color: #ffffff;
    transition: flex 0.1s ease;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    /* min-width: 200px; */
    position: relative;
  }

  .sidebar.closed {
    flex: 0.7;
    font-size: 10px;
    position: relative;
  }

  .iconSideBarArrow{
    position: absolute;
    right: 85px;
    top: 5px;
    color: rgb(196, 196, 196);
    cursor: pointer;
  }
  .iconSideBarArrow.closed{
    position: absolute;
    right: 40px;
    top: 5px;
    color: rgb(196, 196, 196);
    cursor: pointer;
  }

  
  
  .topLogoSidebar {
  position: absolute;
  top: 39px;
  }
  
  .topLogoSidebar img {
   width: 55%;
   height: 100%;
  }

  .topLogoSidebar.closed {
    position: absolute;
    top: 40px;
  }
  
  .topLogoSidebar.closed img {
   width: 55%;
   height: 100%;
  }

  .centerSidebar {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    max-height: 220px;
    margin-top: 80px;
}
  .centerSidebar.closed {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    max-height: 220px;
    margin-top: 65px;
}
  
  .centerImgSidebar {
    width: 100px; 
    height: 100px; 
    border-radius: 50%; 
    overflow: hidden;
  }
  
  .centerImgSidebar img {
    width: 100%; 
    height: auto; 
  }

.centerImgSidebar.closed{
     width: 70px; 
    height: 70px; 
    border-radius: 50%; 
    overflow: hidden;
}
.centerImgSidebar.closed img{
  width: 100%; 
  height: auto; 
}
  
  .centerNameSidebar{
    font-size: 18px;
  }
  .centerNameSidebar.closed{
    font-size: 14px;
  }

  .centerSignOutSidebar{
    cursor: pointer;
    font-size: 12px;
    color: var(--primary-color);
  }
  
  .centerSignOutSidebar.closed{
    cursor: pointer;
    font-size: 10px;
    color: var(--primary-color);
  }
  
  .bottomLinkSidebar {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bottomLinkSidebar.closed {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .wrapperItemSidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    height: 100%;
    width: 100%;
}
  .wrapperItemSidebar.closed {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    height: 100%;
    width: 100%;
    margin-right: 10px;
}
  
.singleItemSidebar {
  display: flex;
  align-items: center;
  color: #b4b5c0;
  padding: 16px;
  cursor: pointer;
  width: 88%;
  box-sizing: border-box; 
  max-width: 250px;
  margin-left: 26px; 
  gap: 10px;
}

  .singleItemSidebar span{
      text-align: left;
  }
.singleItemSidebar.closed {
  display: flex;
  align-items: center;
  color: #b4b5c0;
  padding: 16px;
  cursor: pointer;
  width: 88%;
  box-sizing: border-box; 
  max-width: 250px;
  margin-left: 26px; 
}

  .singleItemSidebar.closed span{
      text-align: left;
  }


  .link:hover,
  .link.selected {
    background-color: rgba(224, 224, 224, 0.146);
    border-left-color: var(--primary-color); /* Change seulement la couleur de la bordure au survol */
  }

.link.selected .singleItemSidebar,
.link:hover .singleItemSidebar {
  color: rgb(90, 90, 90); 
}

.link.selected .singleItemSidebar .iconContainer svg,
.link:hover .singleItemSidebar .iconContainer svg {
  fill: var( --primary-color); 
}

  
  .selectedText {
    color: #000000;
  }
  
  .iconContainer {
    display: flex;
    align-items: center;
    margin-right: 2px;
  }
  
  .iconContainer.closed {
    display: flex;
    align-items: center;
    margin-right: 2px;
  }
  
  .singleItemSidebar.closed svg {
    margin-right: 10px;
  }
  
  .singleItemSidebar.closed span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    flex: 1; 
  }

  .link {
    text-decoration: none;
    color: inherit;
    width: 100%;
    cursor: pointer;
    border-left: 4px solid transparent;
    box-sizing: border-box; 
  }
  .link.closed {
    text-decoration: none;
    color: inherit;
    width: 105%;
    cursor: pointer;
    border-left: 4px solid transparent;
    box-sizing: border-box; 
  }

  .link:hover {
   background-color: rgba(224, 224, 224, 0.146);
   border-left: 4px solid var(--primary-color)
   ;
  }
  
  /* .link.active .singleItemSidebar {
    background-color: rgba(224, 224, 224, 0.146);
    border-left: 4px solid var(--primary-color);
  }
   */

  @media (max-width: 576px) {
    .wrapperItemSidebar {
      width: 90%;
    }
    .singleItemSidebar {
      max-width: 100%; /* Pour une largeur maximale de 100% sur les écrans plus petits */
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    .wrapperItemSidebar {
      width: 80%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    .wrapperItemSidebar {
      width: 60%;
    }
  }
  
  @media (min-width: 993px) and (max-width: 1200px) {
    .wrapperItemSidebar {
      width: 50%;
    }
  }
  