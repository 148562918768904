.login-page-Plateforne {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-image: url("/public/images/defaultImg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; */
  }
  
  
  .formLoginPagePlateforme{
    background-color: rgb(255, 255, 255);
    width: 25%;
    display: flex;
    flex-direction: column;
    height: 55vh;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); 
    }
    
    .topFormLoginPlateforme{
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .topFormLoginPlateforme img {
      max-width: 250px;
      max-height: 150px; 
      width: auto; 
      height: auto;
      object-fit: contain;
    }
  
    .middleFormLoginPlateforme{
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
  
  .middleFormLoginPlateformeInput{
    background-color: white;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid rgb(193, 193, 193);
    transition: border-color 0.3s; 
  
  }
  
  .middleFormLoginPlateformeInput label{
    color: grey;
    font-size: 12px;
    text-align: left;
    padding: 5px;
    margin-left: 10px;
    margin-top: 5px;
  }
  .middleFormLoginPlateformeInput input{
    padding: 5px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    padding-bottom: 10px;
  }
  
  .middleFormLoginPlateformeInput:focus-within {
    border-color: #94a2cb; 
  }
  
  .middleFormLoginPlateformeInput input:focus {
    outline: none; 
  }
    .bottomFormLoginPlateforme{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bottomFormLoginPlateforme button{
      padding: 18px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      background-color: #94a2cb;
      width: 80%;
      color: white;
    }
    .bottomFormLoginPlateforme button:hover{
      background-color: #94a2cbde;
    }
  
  
   