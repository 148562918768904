.profileInfo{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: 60%;
    height: 70%;
    max-width: 90%; 
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.closeIconProfileInfo svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
  font-size: 18px;
}

.profileInfoWrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%; 
}

.topProfileInfo{
    display: flex;
}
.topProfileInfo span{
    font-size: 18px !important; 
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}

.middleProfileInfo{
    flex-grow: 1; 
    display: flex;
    gap: 10px;
    padding: 10px;
    
}

.middleProfileInfo label{
    font-size: 14px;
    color: rgb(178, 178, 178);
    width: 100%;
}

.middleProfileInfo input{
        padding: 5px;
        border: 1px solid rgb(167, 167, 167);
        width: 90%;
}

.middleProfileInfoLeft{
    flex: 0.5;
    padding: 10px;
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.middleProfileInfoLeftItem {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 5px;
    /* border: 1px solid black; */
    color: #93a1ca;
    font-size: 14px;
    border-bottom: 1px solid rgb(245 241 241);
}

.middleProfileInfoLeftItem svg{
    font-size: 30px;
}

.middleProfileInfoLeftItem:hover{
    background-color: rgba(210, 210, 210, 0.098);
    border-radius: 5px;
}

.middleProfileInfoRight{
    flex: 1.5;
    /* border: 1px solid black; */
    height: 400px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 10px;
}

.bottomProfileInfo{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomProfileInfo button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButtonProfileInfo {
    background-color: transparent;
    color: grey;
}

.cancelButtonProfileInfo:hover {
    background: #df525e;
    color: white;
}

.saveButtonProfileInfo {
    background-color: #93a1ca;
    color: var(--white-text);
}

.saveButtonProfileInfo:hover {
    background-color: #0e8b6e;
}

.bottomProfileInfo button svg {
    fill: var( --svg-color); 
    font-size: 16px;
}


.middleFormLoginPlateformeInputProfile{
    background-color: white;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid rgb(193, 193, 193);
    transition: border-color 0.3s; 
  }


  .middleFormLoginPlateformeInputProfile label{
    color: grey;
    font-size: 12px;
    text-align: left;
    padding: 5px;
    margin-left: 10px;
    margin-top: 5px;
  }
  .middleFormLoginPlateformeInputProfile input{
    padding: 5px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    padding-bottom: 10px;
  }
  
  .middleFormLoginPlateformeInputProfile:focus-within {
    border-color: #94a2cb; 
  }
  
  .middleFormLoginPlateformeInputProfile input:focus {
    outline: none; 
  }


  .middleProfileInfoWithPP {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    text-align: center;
    gap: 10px;
    border-right: 0.5px solid rgb(230, 230, 230);
    padding: 5px 20px;
}

  .profilPopUp{
	float: right;
	width: 100px;
	height: 100px;
	background: #93a1ca42;
	border-radius: 100%;
	text-align: center;
	color: #93a1ca;
	text-transform: uppercase;
	line-height: 30px;
	font-size: 26px;
	font-weight: 500;
	cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.titleProfileinfoPopup{
    text-align: left;
    margin-left: 10px;
    font-size: 19px;
    color: #93a1ca;
    border-bottom: 0.5px solid #93a1ca;
    padding: 10px 0px;
    margin-bottom: 10px;
}