.createNewApt{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: 25%; 
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconNewApt svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.createNewAptWrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.topCreateNewApt{
    display: flex;
}
.topCreateNewApt span{
    font-size: 20px;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}


.middleCommentPopupNewEvent{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.middleCommentPopupNewEvent textarea{
    width: 65%;
    border-radius: 6px;
    border: 1px solid rgb(178, 178, 178);
    background: #fff;
    margin-bottom: 10px;
}

.middleCreateNewApt{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 18px;
    padding: 10px;
}

.middleCreateNewApt label{
    font-size: 14px;
    color: rgb(178, 178, 178);
}

.middleCreateNewApt input{
        padding: 5px;
        border: 1px solid rgb(167, 167, 167);
}
.middleCreateNewApt select{
    padding: 5px;
    width: 60%;
    text-align: center;
    margin: auto;
    cursor: pointer;
    border: 1px solid rgb(167, 167, 167);
}

.datePickerTriggerNewEvent {
    cursor: pointer;
    padding: 8px;
    border: 1px solid #ccc;
    font-size: 13px;
    width: 30%;
  }
  
  .datePickerContainerNewEvent {
    position: absolute;
    z-index: 100;
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
  }

  .datePickerContainerNewEvent select{
    width: 100px;
  }


.dateAndHour{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}


.bottomCreateNewApt{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomCreateNewApt button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButton {
    background-color: transparent;
    color: grey;
}

.cancelButton:hover {
    color: #e76790;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.bottomCreateNewApt button svg {
  fill: var( --svg-color); 
}

.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  
  
  .searchContainer input{
    width: 200px;
  }
  
  .searchContainer input::placeholder{
    font-size: 10px;
    color: rgb(144, 144, 144);
  }
  


.searchResults {
    list-style: none;
    position: absolute;
    top: 70%;
    left: 0;
    width: 213px;
    background-color: var(--white-background);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
  }
  
  .searchResults li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .searchResults li:hover {
    background-color: #f0f0f0;
  }
  
  .searchResultIcon {
    flex-shrink: 0; 
    margin-right: 15px; 
    color: var(--primary-color);
  }
  
  .searchResultDetails {
    flex-grow: 1; 
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    gap: 5px;
    font-size: 12px;
  }
  
  .searchResultName {
    font-weight: 200;
    color: black;
  }
  
  .searchResultId {
    font-size: 0.8em;
    color: var(--primary-color); 
  }
  
  