.crmPage {
    display: flex;
    height: 100vh; /* Pleine hauteur */
    overflow: hidden; /* Empêche le défilement au niveau de la racine */
}

.crmPageContainer {
    flex: 8; /* Ajustez en fonction de l'espace restant après la sidebar */
    overflow-y: auto; /* Permet au contenu de scroller indépendamment */
    padding: 30px;
}


.bottomCrmPage {
    flex-grow: 1; /* Prend tout l'espace vertical disponible */
}
