.dashboardCampaignBottomWrapper{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.dashboardCampaignBottomWrapperItem{
    border-left: 4px solid #6cbba9;
    padding: 5px;
}


.dashboardCampaignBottomWrapperItemTop span{
    font-size: 12px;
}

.dashboardCampaignBottomWrapperItemBottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.dashboardCampaignBottomWrapperItemBottom span{
    color: rgb(182, 182, 182);
    font-size: 11px;
}

.dashboardCampaignBottomWrapperItemTopAndBottom{
    text-align: left;
    margin-left: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.overviewCampaignSelect{
    display: flex;
    gap: 10px;
}

.overviewCampaignSelect select{
    padding: 4px 0px;
    border: 1px solid rgb(201, 201, 201);
    color: #c0c0c0;
    cursor: pointer;
    font-size: 11px;
}


.overviewCampaignSelect button{
    padding: 6px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 200;
    background-color: var(--primary-color);
    color: var(--white-text);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .overviewCampaignSelect button span {
   margin-right: 5px;
}
  .overviewCampaignSelect button svg{
    font-size: 18px;
  }

  .overviewCampaignSelect button:hover{
    background-color: #00b161d1;
    color: var(--white-text);
  }
  