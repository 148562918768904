.withdrawal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 0px;
    z-index: 9999;
    width: 20%; 
    max-width: 90%; 
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}





.middleWithdrawal{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.middleWithdrawal label{
    font-size: 14px;
    color: rgb(178, 178, 178);
    width: 100%;
}

.middleWithdrawal input{
        padding: 5px;
        border: 1px solid rgb(167, 167, 167);
        width: 100%;
}


.bottomBuyButton{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomBuyButton button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButtonBuyButton {
    background-color: transparent;
    color: grey;
}

.cancelButtonBuyButton:hover {
    background: #df525e;
    color: white;
}

.saveButtonBuyButton {
    background-color: #93a1ca;
    color: var(--white-text);
}

.saveButtonBuyButton:hover {
    background-color: #0e8b6e;
}

.bottomBuyButton button svg {
    fill: var( --svg-color); 
    font-size: 16px;
}
