.buyButton{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: auto; 
    max-width: 90%; 
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconBuyButton svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
  font-size: 14px;
}

.buyButtonWrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.topBuyButton{
    display: flex;
}
.topBuyButton span{
    font-size: 16px !important; 
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}



.middleBuyButton{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.middleBuyButton label{
    font-size: 14px;
    color: rgb(178, 178, 178);
}

.middleBuyButton input{
        padding: 5px;
        border: 1px solid rgb(167, 167, 167);
}

.middleBuyButtonTop{}
.middleBuyButtonTop svg{
    font-size: 150px;
    color: #93a1cac5;
}

.middleBuyButtonMiddle{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.middleBuyButtonMiddle span:nth-child(1) {
    font-size: 20px;
}
.middleBuyButtonMiddle span:nth-child(2) {
    color: grey;
    font-size: 16px;
}

.middleBuyButtonBottom{

}

.bottomBuyButton{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomBuyButton button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButtonBuyButton {
    background-color: transparent;
    color: grey;
}

.cancelButtonBuyButton:hover {
    background: #df525e;
    color: white;
}

.saveButtonBuyButton {
    background-color: #93a1ca;
    color: var(--white-text);
}

.saveButtonBuyButton:hover {
    background-color: #0e8b6e;
}

.bottomBuyButton button svg {
    fill: var( --svg-color); 
    font-size: 16px;
}


.loading-container{
    width: 300px;
    height: 70px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 10px
}
.loading-container span{
    color: rgb(184, 184, 184);
    font-size: 14px;
}   