
.dashboardContainerTopRight{
    flex: 1;
    background-color: var(--white-background);
    border-radius: 5px;
}

.salePerfomanceWrapperDashboard{
    /* border: 1px solid black; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.salePerfomanceWrapperDashboardTop {
    display: flex;
    justify-content: center;
    align-items: flex-end; 
    gap: 110px;
  }

  .salePerfomanceWrapperDashboardTopFirst,
.salePerfomanceWrapperDashboardTopSecond,
.salePerfomanceWrapperDashboardTopThird {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  color: grey;
  align-items: center;
  transition: all 0.3s ease; 
}

.salePerfomanceWrapperDashboardTopFirst,
.salePerfomanceWrapperDashboardTopThird {
  margin-top: 20px; 
}

.salePerfomanceWrapperDashboardTopFirst img,
.salePerfomanceWrapperDashboardTopThird img {
  width: 45px; 
}

.salePerfomanceWrapperDashboardTopFirst {
    display: flex;
    flex-direction: column;
    gap: 7px;
    font-size: 12px;
    color: grey;
    align-items: center;
}

.salePerfomanceWrapperDashboardTopFirstRanking{
    width: 15px;
    height: 15px;
    background-color: #81cfbd;
    color: var(--white-text);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
}
.salePerfomanceWrapperDashboardTopFirstRankingFirst{
    width: 15px;
    height: 15px;
    background-color: #82c6d0;
    color: var(--white-text);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
}

.salePerfomanceWrapperDashboardTopSecond {
    display: flex;
    flex-direction: column;
    gap: 7px;
    font-size: 12px;
    color: grey;
    align-items: center;
}


.salePerfomanceWrapperDashboardTopThird {
    display: flex;
    flex-direction: column;
    gap: 7px;
    font-size: 12px;
    color: grey;
    align-items: center;
}


.salePerfomanceWrapperDashboardTopFirst img{
    width: 35px;
}
.salePerfomanceWrapperDashboardTopSecond img{
    width: 55px;
}
.salePerfomanceWrapperDashboardTopThird img{
    width: 35px;
}

.salePerfomanceWrapperDashboardBottom{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* border: 1px solid black; */
    height: 11vh;
    overflow: auto;
    margin-top: 5px;
}

.salePerfomanceWrapperDashboardBottomItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 90%;
    /* gap: 135px; */
}

.salePerfomanceWrapperDashboardBottomItemLeft{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}

.salePerfomanceWrapperDashboardBottomItemLeft img{
    width: 30px;
}

.salePerfomanceWrapperDashboardBottomItemRight{
    font-size: 12px;
    color: grey;
}