/* General Classment */

.salesPerfomancePageBottomLeftTopItemWithSelect{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.salesPerfomancePageBottomLeftTopItemWithSelectLeft{
    display: flex;
    align-items: center;
    color: #c0c0c0;
    gap: 5px;
}
.salesPerfomancePageBottomLeftTopItemWithSelectLeft span{
    font-size: 11px;
}
.salesPerfomancePageBottomLeftTopItemWithSelectLeft svg{
    font-size: 14px;
}

.salesPerfomancePageBottomLeftTopItemWithSelectRight{
    display: flex;
    gap: 10px;
}

.salesPerfomancePageBottomLeftTopItemWithSelectRight select{
    padding: 4px 0px;
    border: 1px solid rgb(201, 201, 201);
    color: #c0c0c0;
    cursor: pointer;
    font-size: 11px;
}




.salesPerfomancePageItemTopGeneral{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #c0c0c0;
    gap: 5px;
    padding: 10px;
    background-color: var(--white-background);
}

.salesPerfomancePageItemTopGeneralLeft{
    display: flex;
    align-items: center;
    gap: 5px;

}
.salesPerfomancePageItemTopGeneralLeft span{
    font-size: 11px;
}
.salesPerfomancePageItemTopGeneralLeft svg{
    font-size: 14px;
}


.rankingWrapper{
    padding: 10px;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    gap: 23px;
    height: 31vh;
    overflow: auto;
    border-top: 4px solid #f5f6fa;
}

.rankingWrapperItem{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    padding: 6px 0px;
}
.rankingWrapperItem:hover{
    background-color: rgba(241, 241, 241, 0.463);
}

.rankingWrapperItemLeftSide{
      /* border: 1px solid black; */
      flex: 0.3 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgb(129 207 189);
      height: 70%;
  
}
.rankingWrapperItemLeftSide span{
    color: #bebebe;
    font-weight: 400;
}
.rankingWrapperItemImgSide{
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-text);
    font-weight: 500;
    font-size: 12px;
}

.rankingWrapperItemImgSide img{
    width: 35px;
    height: 100%;
}

.rankingWrapperItemRightSide{
    /* border: 1px solid black; */
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    text-align: left;
}
.rankingWrapperItemRightSideTop{
    font-size: 14px;
}

.rankingWrapperItemRightSideBottom{
    font-size: 11px;
    color: rgb(196, 196, 196);
} 
.rankingWrapperItemRightSideBottom span{
    line-height: 1.5;
} 