.salesPerfomancePageTop{
    height: 100px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.salesPerfomancePageTopWrapper{
    display: flex;
    height: 80%;
    flex: 1;
    gap: 10px;
}



/* BOTTOM */

.salesPerfomancePageBottom{
    display: flex;
    height: 70vh;
    gap: 10px;
}

.salesPerfomancePageBottomLeft{
    flex: 1.5;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}

.salesPerfomancePageBottomLeftTop{
    flex: 1.2;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    border-bottom: 10px solid white;
}

.salesPerfomancePageBottomLeftBottom{
    flex: 1;
    display: flex;
    gap: 10px;
}
.salesPerfomancePageBottomLeftBottomRight{
    flex: 1;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    overflow: auto;
    border-radius: 5px;
}

.salesPerfomancePageBottomRight{
    flex: 1;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    overflow: auto;
    border-radius: 5px;
    border-bottom: 10px solid white;
  }
  


  .loaderOverlayPerfomancePage {
    position: absolute;
    top: 95px;
    left: 210px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.381);
    z-index: 10;
    height: 610px;
    width: 83%;
  }
 