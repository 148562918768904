
.selectOptionsWrapper{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-background);
  border-radius: 5px;
  z-index: 9999;
  width: auto; 
  max-width: 90%; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center; 
}

.closeIconStatus svg{
position: absolute;
top: 5px;
right: 5px; 
color: rgb(178, 178, 178);
cursor: pointer;
}

.selectOptionsWrapperWrapper{
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.topSelectOptions{
  display: flex;
}
.topSelectOptions span{
  font-size: 18px;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid rgb(205, 205, 205);
  color: rgb(178, 178, 178);
  text-align: left;
  font-weight: 300;
}



.middleSelectOptions{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 300;
}
.middleSelectOptions span{
  font-size: 14px;
  color: rgb(178, 178, 178);
  margin-bottom: 10px;
}

.middleSelectOptions .checkboxOption {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.middleSelectOptions .checkboxOption input[type="radio"] {
  margin-right: 10px;
  cursor: pointer;
  width: 20px; /* Taille externe du bouton radio */
  height: 20px; /* Taille externe du bouton radio */
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  border: 2px solid #ccc; /* Bordure grise */
  background-color: var(--white-background);
  outline: none;
}

.middleSelectOptions .checkboxOption input[type="radio"]:checked {
  background-color: var(--primary-color); /* Couleur de fond pour le bouton sélectionné */
  border: 2px solid var(--primary-color); /* Bordure de la même couleur */
  box-shadow: inset 0 0 0 4px white; /* Ajoute un espace blanc à l'intérieur */
}

.middleSelectOptions .checkboxOption label {
  font-size: 14px;
  cursor: pointer;
  color: rgb(178, 178, 178);
}



.bottomSelectOptions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 5px;
  width: 100%;
}

.bottomSelectOptions button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.cancelButton {
  background-color: transparent;
  color: grey;
}

.cancelButton:hover {
  color: #e76790;
}

.saveButton {
  background-color: var(--primary-color);
  color: var(--white-text);
}

.saveButton:hover {
  background-color: var(--primary-color-hover);
}

.bottomSelectOptions button svg {
  fill: var( --svg-color); 
}
