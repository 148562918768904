.messageNoNothing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:  50px;
}
.messageNoNothing span{
    font-size: 25px;
    color: rgb(237, 237, 237);
}
.messageNoNothing svg{
    font-size: 100px;
    color: rgb(237, 237, 237);
}