.bottombar{
	display: none;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    line-height: 80px;
    height: 80px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	border-top: 1px solid #e9e9e9;
	background: #ffffff91;
}

.menbotin{
	height: 80px;
	line-height: 15px;
	text-align: center;
	color: #93a1ca !important;
}

.botimg{
	margin-top: 20px;
	position: relative;
}

.menbot{
	height: 80px;
	line-height: 15px;
	text-align: center;
	cursor: pointer;
}

.menbot:hover{
	color: #93a1ca !important;
}

.txtmenbot{
	font-size: 12px;
	font-weight: 300;
}
.notif{
	width: 5px;
		height: 5px;
		display: block;
		background: #df525e;
		border-radius: 100%;
		position: absolute;
		top: 3px;
		right: -6px;
	}

	.overlay-panel {
    position: fixed;
    bottom: 80px;
    left: 0;
    right: 0;
    height: 40vh;
    background-color: #fbfbfd;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid #ccc;
    overflow: auto;
}
	  .overlay-panelCloseIcon{
		position: absolute;
		top: 10px;
		right: 10px;
		color: grey;
	  }