.dashboardCampaignPageTop{
    height: 85px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.dashboardCampaignPageTopWrapper{
    display: flex;
    height: 80%;
    flex: 1;
    gap: 10px;
}

.dashboardCampaignPageBottom{
    display: flex;
    height: 72vh;
    gap: 10px;
}


.dashboardCampaignPageBottom{
    display: flex;
    height: 70vh;
    gap: 10px;
}

.dashboardCampaignPageBottomLeft{
    flex: 1.5;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}

.dashboardCampaignPageBottomLeftTop{
    flex: 1.2;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    border-bottom: 10px solid white;
}

.dashboardCampaignPageBottomLeftBottom{
    flex: 1;
    display: flex;
    gap: 10px;
}
.dashboardCampaignPageBottomLeftBottomRight{
    flex: 1;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    overflow: auto;
    border-radius: 5px;
}

.dashboardCampaignPageBottomRight{
    flex: 1;
    background-color: var(--white-background);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    overflow: auto;
    border-radius: 5px;
    border-bottom: 10px solid white;
  }
  

