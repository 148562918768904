.middleFormLoginPlateformeInputProfile label {
    font-size: 0.85rem; /* Slightly smaller label text */
  }
  
  .passwordCriteria {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.75rem; 
    margin: auto;
    width: 80%;
  }
  
  .passwordCriteria li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: #919191;
  }
  
  .passwordCriteria li .MuiSvgIcon-root {
    font-size: 1rem; 
    margin-right: 8px;
    color: #ccc; 
  }
  
  .passwordCriteria li.valid .MuiSvgIcon-root {
    color: #4CAF50; 
  }
  
  .passwordStrength {
    background-color: #f1f1f1;
    border-radius: 4px;
    height: 8px;
    margin: auto;
    width: 80%;
    overflow: hidden;
  }
  
  .strengthBar {
    height: 8px;
    background-color: green;
    width: 0%;
    transition: width 0.4s ease;
  }
  
  .editButtonPlatformPopUpInfoLeadPassword {
    width: 100%;
    display: flex;
    justify-content: center; /* Center the button within the div */
}

.editButtonPlatformPopUpInfoLeadPassword button {
    width: 80%;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #93a1ca;
    color: white;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.editButtonPlatformPopUpInfoLeadPassword button:hover {
    background-color: #7982a9; /* Slightly darker on hover */
}

.editButtonPlatformPopUpInfoLeadPassword button:disabled {
    background-color: #ccc; /* Gray out the button when disabled */
    color: #666;
    cursor: not-allowed; /* Show a 'not allowed' cursor on hover */
}
