.ql-editor {
    min-height: 200px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap; /* Nouveau */
    word-break: break-all; /* Nouveau */
}


.react-quill {
    width: 100%;
    overflow-x: auto; /* Modifier si nécessaire pour gérer le débordement horizontal */
}