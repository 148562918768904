.dashboardContainerBottomItemRightWrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--white-background);
}

.dashboardContainerBottomItemRightContainer {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
    gap: 10px; 
    height: 80%;
    padding: 10px;
}
.dashboardContainerBottomItemRightItem {
    width: 48%; 
    border: 1px solid rgba(232, 232, 232, 0.513);
    display: flex;
}

.dashboardContainerBottomItemRightItemLeft{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dashboardContainerBottomItemRightItemLeft svg{
    font-size: 25px;
    color: #81cfbd;
}
.dashboardContainerBottomItemRightItemRight{
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.dashboardContainerBottomItemRightItemRight :nth-child(1){
    font-size: 12px;
    color: #c0c0c0;
    font-weight: 300px;
}
.dashboardContainerBottomItemRightItemRight :nth-child(2){
    font-size: 20px;
    color: #7ec5cf;
    font-weight: 400;
}