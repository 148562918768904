.commentAreaPopupCustomer{
    padding: 15px;
  }

  .textAreaCommentPopup{
    border: 1px solid rgb(218, 218, 218);
  }
  .textAreaCommentPopup::placeholder{
    color: rgb(191, 191, 191);
    font-size: 10px;
  }
  .planCommentPopup{
    text-align: left;
}
.planCommentPopup span{
    font-size: 12px;
    color: rgb(178, 178, 178);
}

.planCommentWrapperPopup{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.planCommentWrapperContainerPopup{
    display: flex;
    align-items: center;
    flex: 3;
    gap: 5px;
}
.planCommentWrapperContainerPopup input{
    margin-top: 10px;
    display: flex;
    padding: 5px;
    font-size: 11px;
    border: 1px solid #ccc;
    color: grey;
    cursor: pointer;
}

.datePickerTriggerPopup {
    cursor: pointer;
    padding: 8px;
    border: 1px solid #ccc;
    margin-top: 10px;
    font-size: 11px;
    color: grey;
  }
  
  .datePickerContainerPopup {
    position: absolute;
    z-index: 100;
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
    bottom: 73px;
    right: 210px;
  }

  .planCommentButtonPopup button {
    padding: 8px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 12px;
    margin-top: 10px;
}
.planCommentButtonPopup button svg{
    font-size: 12px;
}

.saveButtonPopup {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButtonPopup:hover {
    background-color: var(--primary-color-hover);
}


.commentAreaWrapperCustomer {
    height: calc(100% - 50px);
    min-height: 300px;
    overflow: unset;
    margin-top: 20px;
    max-height: 60%;
    padding-right: 10px;
    width: 100%;
}

.commentAreaContainerCustomer {
    position: relative;
    height: 300px;
    overflow: auto;
}

.singleCommentCustomer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: solid 1px #c0c0c0;
    align-items: flex-start;
}

.commentAuthorCustomer {
    font-size: 14px;
    color: #777;
    font-weight: 600;
    margin-bottom: 4px;
}

.commentDateCustomer {
    font-size: 12px;
    color: #cecece;
    font-weight: 400;
    margin-bottom: 8px;
}

.commentTextCustomer {
    margin-bottom: auto; 
    color: #979797;
    font-size: 12px;
}

.appointmentInCommentCustomer{
    background-color: #81cfbd;
    padding: 5px;
    font-size: 10px;
    border-radius: 20px;
    margin-top: 1px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--white-text);
    padding: 3px 9px;
    border: none;
}
.appointmentInCommentCustomer svg{
 font-size: 13px;
}

.commentDeleteCustomer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.deleteLinkCustomer {
    color: #81cfbd;
    cursor: pointer;
    text-decoration: none;
    font-size: 11px;
}





.planCommentButtonCustomer button {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.planCommentButtonCustomer button svg{
    font-size: 14px;
}


.errormessage{
    color: rgb(233, 10, 10);
    font-size: 12px;
}
