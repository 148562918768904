.adminPlanning{
    background-color: var(--white-background);
    height: 84vh;
    box-sizing: border-box; 
}

.adminPlanningWrapper{
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%; 
    box-sizing: border-box; 
}

.topAdminPlanning{
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.topAdminPlanningLeft span{
    font-size: 13px;
}
.topAdminPlanningLeft svg{
    font-size: 20px;
}
.topAdminPlanningLeft{
    text-align: left;
    display: flex;
    align-items: center;
    color: #c0c0c0;
    gap: 15px;
    margin-bottom: 10px;
}

.topAdminPlanningRight select{
    font-size: 11px;
      padding: 8px 4px;      
      border: none;
      cursor: pointer;
      color: grey;
      max-width: 109px;
      border: 1px solid rgb(114, 114, 114);
}

.centerAdminPlanning{
    /* border: 1px solid black; */
    margin-top: 10px;
    flex: 1; 
    display: flex;
    flex-direction: column;
    box-sizing: border-box; 
}

.thisMonthContainer, .restOfTheYearContainer{
    flex: 1; 
}

.thisMonthContainerTitle{
    text-align: left;
    font-weight: 500;
    font-size: 14px;
}
.appointmentContainer{
    /* border: 1px solid black; */
    flex: 1; 
    display: flex;
    flex-direction: column;
    height:70vh;
    overflow: auto;
    gap: 35px;
    margin-top: 17px;
}
.appointmentWrapper{
    display: flex;
    flex-wrap: wrap; /* Ajouté pour gérer deux éléments par ligne */
    gap: 5px;
}


.appointmentContainerItem {
    flex-basis: calc(50% - 5px);
    text-align: center;
    border-left: solid 1px #eaeaea;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.delContainer{
    position: absolute;
    font-size: 11px;
    color: #4ccbab;
    bottom: 0;
    right: 5px;
    cursor: pointer;
}
.delContainer:hover{
    color: #4ccbabc3;
}
.rdvContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #81cfbd;
    padding: 4px 14px;
    position: absolute;
    top: 0px;
    right: -6px;
    color: var(--white-text);
    font-size: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.rdvContainer svg{
    font-size: 16px;
}

.appointmentContainerItemName{
    text-align: left;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #777;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 8px;
    margin-left: 10px;
}

.appointmentContainerItemNameWrapper{
    display: flex;
    gap: 10px;
}

.appointmentContainerItemNameWrapperNameImg{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.appointmentContainerItemNameWrapperNameImg img{
    width: 25px;
}

.appointmentContainerItemNameWrapperNameImgDate{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.dateColor{
    font-size: 12px;
    margin-bottom: 4px !important;
    color: #cecece;
    font-weight: 400;
    margin-top: 2px;
}

.appointmentContainerItemComentaire{
    color: #b6b6b6;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 450px;
    margin: auto;
    height: 8vh; /* Hauteur fixe pour s'assurer de la cohérence */
    max-height: 100px; /* Hauteur maximale pour empêcher un seul commentaire de prendre tout l'espace */
    overflow: auto;
}

.noAppointment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 10px;
}

.noAppointment svg{
    font-size: 200px;
    color: rgb(233, 233, 233);
}
.noAppointment span{
    font-size: 40px;
    color: rgb(233, 233, 233);
}