.popupRigthSideWrapper{
    padding: 10px;
    height: 100%;
}

.popupTopNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.popupRigthSideTop{
    display: flex;
    gap: 15px;
    text-align: left;
    padding: 0px 15px; 
}

.popupRigthSideTopItem{
    font-size: 13px;
    cursor: pointer;
    color: rgb(158, 158, 158);
    padding: 5px;
}

  
.popupRigthSideTopItem.active {
    color: rgb(0, 0, 0);
    border-bottom: 3px solid var(--primary-color);
    font-weight: 400;
  }

  .popupRigthSideBottom{
    padding: 15px;
    /* border: 1px solid black; */
    display: flex;
    gap: 15px;
    height: 88%;
  }

  