
.picturesLibrary{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: 45%; 
    height: auto; 
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconStatus svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.picturesLibraryWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
    height: 70vh;
}



.middlePictureLibrary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); 
    gap: 20px; 
    justify-items: center;
    align-items: center; 
    overflow-y: auto; 
    max-height: 100%;
    padding: 10px; 
  }
  
.pictureItem {
  width: 100%;
  max-width: 150px;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s; /* Ajoute un effet de transition */
}

.pictureItem:hover {
  background-color: rgba(231, 231, 231, 0.676);
}

.selectedPictureLibrary {
  background-color: rgba(232, 232, 232, 0.777);
  transform: scale(1.1); 
}
  
  .middlePictureLibrary span{
    color: grey;
    font-size: 10px;
  }



