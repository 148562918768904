.marketPlacePage{
    background: rgb(255,255,255) ;
    background: linear-gradient(138deg, rgba(255,255,255,1) 0%, rgba(244,247,248,1) 100%) !important;
	margin: 0px;
	height: 100%;
	font-family: "Roboto", sans-serif;
}


  

@media screen and (max-width: 1250px) {
	
	.sidebar-left{
		display: none !important;
	}
	.main{
		left: 0px !important;
	}
	.select-asset{
		cursor: pointer !important;
	}
		.select-asset:hover{
		cursor: pointer !important;
			background: #94a2cb0a !important;
	}
	
	.select-asset .flepl{
			right: 0px !important;
			display: block  !important;
}
	.middleProfileInfoWithPP{

		flex: 0.5;
	}

	.middleProfileInfoLeftItem{
			font-size: 12px !important;
	}


	}

@media screen and (max-width: 1000px) {
	
	.sidebar-right{
		display: none !important;
	}
	.main{
		right: 0px !important;
	}
	
	.menu{
		display: none !important;
	}
	
	.language{
		display: none !important;
	}
	
	.cloche{
		display: none !important;
	}
	
	.menumob{
	
	display: block !important;
}

	.chart{
		bottom: 419px !important;
		
	}
	.widgets{
		bottom: 111px !important;
		
	}
	
	.bottombar{
	display: inline-flex !important;
}
	
	
	}

@media screen and (max-height: 700px) {
	
	.widgets {
		height: 139px !important;
		overflow: auto !important;
	}
	
	.widget-tiers{
		
		height: 100% !important;
	}
	
	.widget-deux-tiers{
		
		height: 100% !important;
	
}
	.chart{
		bottom: 199px !important;
	}
	
	}

@media screen and (max-width: 1000px) and (max-height: 700px) {
	
	.chart{
		bottom: 280px !important;
	}
	
	
}

@media screen and (max-width: 620px) {
	
.wid-margin-right{
	margin-right: 0px !important;
}
.wid-margin-bottom{
	margin-bottom: 5px !important;
}

.widget-tiers {
	margin-left: 5px !important;
	margin-bottom: 5px !important;
	width: calc((100% / 2) - (10px / 2)) 
}
	
.widget-deux-tiers{

	width: 100% !important;
	margin-left: 5px !important;

	
}

.widgets {
		
	overflow: auto !important;
}
	
.mainprice{
	display: none !important;
}

.h1-select-title span{
	display: inline !important;
}
	
	}


@media screen and (max-width: 499px) {	
	
.select-asset {
    margin-top: 17px !important;
    display: inline-block !important;
    height: 44px !important;
    padding-right: 0px !important;
    position: relative !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important; 
    padding-left: 0px !important;
    margin-left: 0px !important;
    width: 100% !important;
}

	.select-asset:hover {
		background: #fff0 !important;

}

	.flepl{
		right: 0px !important;
		display: block  !important;
	}
	
	.h1-select-title span{
		display: none !important;
	}
	
	.asset-chip2 {
    font-size: 7px !important;
    display: block !important;
    margin-top: 1px !important;
    height: fit-content !important;
    line-height: normal !important;
    text-align: center !important;
    width: fit-content !important;
    font-weight: 500;
    text-transform: uppercase !important; 
    float: left !important;
    margin-left: 10px !important;
    border-radius: 2px !important;
    padding: 4px 8px 4px 8px !important;
}
	
.buy {
    display: block !important;
    width: fit-content !important;
    height: fit-content !important;
    border-radius: 3px !important;
    text-align: center !important;
    line-height: normal !important;
    text-transform: uppercase !important;
    background: #0e8b6e !important;
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    position: absolute !important;
    right: 30px !important;
    top: 85px !important;
    cursor: pointer !important;
    padding: 6px 16px 6px 16px !important;
}
	.chart{
		top: 144px !important;
	}
	
.mainprice {
    text-align: left !important;
    position: absolute !important;
    right: auto !important;
    top: 82px !important;
    left: 30px !important;
	display: block !important;
}

	
}

@media screen and (max-height: 450px) and (max-width: 1000px) {	
	
	.chart {
		display: none !important;
	}
	.widgets{
		
		height: auto !important;
		top: 88px !important;
		bottom: 101px !important;
	}



}

