.createProduct{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-background);
    border-radius: 5px;
    z-index: 9999;
    width: 22%; 
    height: 60%;
    max-width: 90%; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; 
}

.closeIconProduct svg{
  position: absolute;
  top: 5px;
  right: 5px; 
  color: rgb(178, 178, 178);
  cursor: pointer;
}

.createProductWrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    justify-content: space-between;
}

.topCreateProduct{
    display: flex;
}
.topCreateProduct span{
    font-size: 18px !important; 
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid rgb(205, 205, 205);
    color: rgb(178, 178, 178);
    text-align: left;
}



.middleCreateProduct{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    overflow: auto;
    align-items: center;
}

.createProductPrensentation{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;
}
.createProductPrensentation select{
    padding: 5px;
    border: 1px solid rgb(167, 167, 167);
    cursor: pointer;
    width: 94%;
}

.middleCreateProduct label{
    font-size: 14px;
    color: rgb(178, 178, 178);
}

.middleCreateProduct input{
        padding: 5px;
        border: 1px solid rgb(167, 167, 167);
        width: 90%;
}


.bottomCreateProduct{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 5px;
    width: 100%;
}

.bottomCreateProduct button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.cancelButton {
    background-color: transparent;
    color: grey;
}

.cancelButton:hover {
    color: #e76790;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--white-text);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.bottomCreateProduct button svg {
    fill: var( --svg-color); 
}
