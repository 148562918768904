.history {
    font-family: Arial, sans-serif;
    width: 100%;
  }
  
  .history-tabs {
    display: flex;
    margin-bottom: -1px;
    justify-content: space-between;
}
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom: none; /* Enlève la bordure du bas pour l'effet collé */
    background-color: var(--white-text);
    margin-right: 4px; /* Espace entre les onglets */
    color: var(--primary-color);
    border-radius: 5px;
    font-size: 12px;
    font-weight: 200;
    border: 1px solid var(--primary-color);
  }
  
  .tab:hover {
    background-color:  var(--primary-color-hover);
    color: var(--white-text);
  }


  .tab.active {
    background-color: var(--primary-color);
    color: var(--white-text);
    border-radius: 5px;
    /* border-color: #ccc; */
  }
  
  .history-content {
    /* border: 1px solid #ccc; */
    padding: 14px 0px;
    height: auto;
    background-color: var( --primary-background);
  }
  

  /* TABLE DESIGN */


  .containerDatatableHistory{
    /* padding: 0px 20px; */
    /* height: 67vh; */
  }

  .datatableHistory{
    position: relative;
    /* -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2); */
    width: 99%;
    overflow-x: auto;
    /* background-color: #fdfdfd; */
    border-radius: 5px;
    height: 66vh;
}

.searchBarDataTable{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}


.tableDataHistory{
  width: 100%;
  border-collapse: collapse;
  background-color: #fdfdfd;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2);
}

.tableDataHistory thead {
  border: 1px solid #dfdfdf66;
  font-size: 12px;
}

.tableDataHistory th {
  padding: 20px;
  color: rgb(171, 171, 171);
  cursor: pointer;
}

.tableDataHistory tbody {
  text-align: center;
  border: 1px solid #dfdfdf66;
}

.tableDataHistory tbody tr {
  border: 1px solid #dfdfdf66;
}


.tableDataHistory td:hover {
  background-color: #00b1611b;
  cursor: pointer;
}

.tableDataHistory tbody tr td  {
  padding: 20px;
  font-size: 10px;
  text-align: left;
  color: rgb(62, 62, 62);
  font-weight: 400;
} 

.tableDataHistory tbody tr td select {
  padding: 4px;
  border: 1px solid var(--primary-color) ;
  font-size: 10px;
  cursor: pointer;
  text-align: left;
  border-radius: 5px;
  color: rgb(62, 62, 62);
  } 

  .tableDataHistory tbody tr.highlighted {
    background-color: #00b1611f;

  }
  
  .tableDataHistory tbody tr:hover {
    background-color: #00b16116;

  }
  
  .tableDataHistory tbody tr:hover td:first-child {
    background-color: transparent;
  }
  

  .tableDataHistory tbody tr:hover td:first-child .container input {
    background-color: transparent;
  }

  .tableDataHistory tbody tr.highlighted td {
    background-color: #00b1610b;
  }

  