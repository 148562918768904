.connectionHistory{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    z-index: 9999;
    width: 35%; /* Adjust width based on content */
    max-width: 90%; /* Don't let it stretch too far on small screens */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center; /* Center the text */
}



.connectionHistory table {
    width: 100%; 
    border-collapse: collapse; 
}

.connectionHistory th, .connectionHistory td {
    border: 1px solid #ddd; 
    padding: 8px; 
    text-align: left; 
    font-size: 12px;
}

.connectionHistory th {
    background-color: #f4f4f4; 
    color: black; 
}
