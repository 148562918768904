.companyName {
    height: 30px;
    cursor: pointer;
    text-indent: 4px;
    border-radius: 6px;
    border: 1px solid #efefef;
    background: #fff;
    margin-bottom: 10px;
    width: 100%;
}

.textAreaAddress {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #efefef;
    background: #fff;
    margin-bottom: 10px;
}