.container input {
    display: none;
  }
  .placeCheckbox{
      /* margin-left: 5px; */
  }
  
  .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  input:focus,
select:focus,
textarea:focus{
    outline: none;
}

 /* Create a custom checkbox */
 .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1.3em;
    width: 1.25em;
    background-color: #2196F300;
    border-radius: 0.25em;
    transition: all 0.25s;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: var(--primary-color);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    transform: rotate(0deg);
    border: 0.1em solid rgb(193, 193, 193);
    left: 0;
    top: 0;
    width: 1.05em;
    height: 1.05em;
    border-radius: 0.25em;
    transition: all 0.25s, border-width 0.1s;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    left: 0.45em;
    top: 0.25em;
    width: 0.25em;
    height: 0.5em;
    border-color: #fff0 white white #fff0;
    border-width: 0 0.15em 0.15em 0;
    border-radius: 0em;
    transform: rotate(45deg);
  }
  /*  CHECKBOX DESIGN */


  .containerDatatable{
    /* padding: 0px 20px; */
    height: 50vh;
  }
  
    .datatable{
        /* padding: 20px; */
        padding: 10px;
        /* -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2); */
        width: 99%;
        overflow-x: auto;
        /* background-color: #fdfdfd; */
        border-radius: 5px;
    }
    .searchBarDataTable{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    
    .leftElementsTable{
      display: flex;  
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
  }
   
    .leftElementsTable span{
        font-size: 13px;
        font-weight: 500;
    }
   
    .leftElementsTable select{
      font-size: 11px;
      padding: 8px 4px;      
      border: none;
      cursor: pointer;
      color: grey;
      max-width: 109px;
    }

    .greenBackgroundAfterSelect {
      background-color: #e0f9f2;
      color: green !important;
    }

    .selectWithCross {
      -webkit-appearance: none; /* pour les navigateurs WebKit */
      -moz-appearance: none; /* pour Firefox */
      appearance: none; /* pour les standards modernes */
      position: relative;
    }
    
    .selectWithCross::after {
      /* Ajouter ici les styles pour votre icône de croix */
      content: '✖'; /* Exemple avec un caractère Unicode, mais vous pouvez utiliser une image ou une icône de fonte */
      position: absolute;
      right: 10px; /* Ajuster en fonction de la position souhaitée */
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .leftElementsTable label{
      font-size: 11px;
      color: grey;
    }



    .dateHeaderChange{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
   
    .rightElements {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px; 
    }

    .rightElements button{
      padding: 6px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 11px;
      font-weight: 200;
      background-color: var(--primary-color);
      color: var(--white-text);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    .rightElements button span {
     margin-right: 5px;
  }
    .rightElements button svg{
      font-size: 18px;
    }

    .rightElements button:hover{
      background-color: var(--primary-color-hover);
      color: var(--white-text);
    }

    .rightElements .searchContainer {
      position: relative;
      display: flex;
      align-items: center;
    }
    
    .rightElements .searchContainer button, 
    .rightElements .searchContainer .searchInput {
      padding: 6px;
      border: none;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 200;
      background-color: #ffffff;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      transition: all 0.3s ease;
    }

    .searchInput{
      background-color: rgb(255, 255, 255) !important;
      color: black !important; 
      border: 1px solid #ccc; 
      border-radius: 5px; 
      padding: 6px 12px;
      font-size: 14px; 

    }
    .rightElements .searchContainer .searchInput:focus {
      outline: none; 
      box-shadow: 0 0 3px var(--primary-color); 
    }
    
    .rightElements .searchContainer .searchInput {
      width: 150px; 
      padding: 6px 12px;
    }
    
    .rightElements .searchContainer button:hover{
      background-color:  var(--primary-color-hover);
      color: var(--white-text);
    }


    .searchResults {
      list-style: none;
      position: absolute;
      top: 99%;
      left: 0;
      width: 174px;
      background-color: var(--white-background);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 10;
      max-height: 200px;
      overflow-y: auto;
      padding: 0;
      font-size: 12px;
  }
    
    .searchResultItem {
      padding: 8px 12px;
      cursor: pointer;
      border-bottom: 1px solid #eee;
    }
    
    .searchResultItem:last-child {
      border-bottom: none;
    }
    
    .searchResultItem:hover {
      background-color: #f9f9f9;
    }
    

    /* TABLE */
    
    .tableData{
      width: 100%;
      border-collapse: collapse;
      background-color: #fdfdfd;
      -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 5px rgba(201, 201, 201, 0.2);
    }
    
    .tableData thead {
      border: 1px solid #dfdfdf66;
      font-size: 12px;
    }
  
    .tableData th {
      padding: 20px;
      color: rgb(171, 171, 171);
      cursor: pointer;
    }

    .headerWithIcon {
      display: flex;
      align-items: center;
      justify-content: flex-start;  
      font-size: 11px;
    }
  
  .headerWithIcon svg {
      margin-left: 5px; 
      font-size: 16px;
  }

  .tableData tbody {
    text-align: center;
    border: 1px solid #dfdfdf66;
  }

.tableData tbody tr {
    border: 1px solid #dfdfdf66;
  }


.tableData td:hover {
    background-color: #00b1611b;
    cursor: pointer;
  }
    
    .tableData tbody tr td  {
        padding: 20px;
        font-size: 10px;
        text-align: left;
        color: rgb(62, 62, 62);
        font-weight: 400;
      } 

      .hover-effect {
        color: var(--primary-color);
        cursor: pointer; 
        margin-right: 10px;
      }
      
      .hover-effect:hover {
        text-decoration: underline; 
      }
      
      .hover-effect:last-child {
        margin-left: 5px;
      }
      
      
      .tableData tbody tr td select {
      padding: 6px;
      border: 1px solid #b8bcbf ;
      font-size: 10px;
      cursor: pointer;
      text-align: left;
      border-radius: 5px;
      color: rgb(62, 62, 62);
      appearance: none;
      background-color: white;
      } 


      .nameAndStatus{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 14px;
      }

      .circleAgence {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-weight: 350;
        font-size: 14px;
        text-transform: uppercase;
      }


      .rondConnectStatus{
        width: 10px;
        height: 10px;
        background-color: #5a5a5ad6;
        border-radius: 50%;
      }

      .rondConnectStatus.online{
        background-color: var(--primary-color);
      }
    
      /* Pagination */
    
      .pagination {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin-top: 20px;
      }
      
      .pagination li {
        display: inline-block;
        margin-right: 5px;
      }
      
      .pagination li a {
        display: block;
        padding: 5px 10px;
        color: #333;
        text-decoration: none;
        cursor: pointer;
        font-size: 14px;
      }
      
      .pagination li a:hover {
        background-color: var(--primary-color);
      }
      
      .pagination .active a {
        background-color: var(--primary-color);
        color: #fff;
      }
      
      .pagination .break {
        padding: 5px 10px;
        border: none;
        background-color: transparent;
      }
      
      .pagination .disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    
      .tableData tbody tr.highlighted {
        background-color: #00b1611f;

      }
      
      .tableData tbody tr:hover {
        background-color: #00b16116;

      }
      
      .tableData tbody tr:hover td:first-child {
        background-color: transparent;
      }
      
    
      .tableData tbody tr:hover td:first-child .container input {
        background-color: transparent;
      }
    
      .tableData tbody tr.highlighted td {
        background-color: #00b1610b;
      }

      
      .red {
        background-color: rgba(244, 67, 54, 0.2); /* Rouge clair */
        color: rgba(244, 67, 54, 0.8); /* Rouge */
      }
      
      .blue {
        background-color: rgba(33, 150, 243, 0.2); /* Bleu clair */
        color: rgba(33, 150, 243, 0.8); /* Bleu */
      }
      
      .purple {
        background-color: rgba(156, 39, 176, 0.2); /* Violet clair */
        color: rgba(156, 39, 176, 0.8); /* Violet */
      }
      
      .green {
        background-color: rgba(76, 175, 80, 0.2); /* Vert clair */
        color: rgba(76, 175, 80, 0.8); /* Vert */
      }
      
      .pink {
        background-color: rgba(233, 30, 99, 0.2); /* Rose clair */
        color: rgba(233, 30, 99, 0.8); /* Rose */
      }
      
      /* Nouvelles couleurs ajoutées */
      .orange {
        background-color: rgba(255, 152, 0, 0.2); /* Orange clair */
        color: rgba(255, 152, 0, 0.8); /* Orange */
      }
      
      .yellow {
        background-color: rgba(255, 235, 59, 0.2); /* Jaune clair */
        color: rgb(211 194 52 / 80%);
      }
      
      .cyan {
        background-color: rgba(0, 188, 212, 0.2); /* Cyan clair */
        color: rgba(0, 188, 212, 0.8); /* Cyan */
      }
      
      .teal {
        background-color: rgba(0, 150, 136, 0.2); /* Sarcelle clair */
        color: rgba(0, 150, 136, 0.8); /* Sarcelle */
      }
      
      .brown {
        background-color: rgba(121, 85, 72, 0.2); /* Marron clair */
        color: rgba(121, 85, 72, 0.8); /* Marron */
      }
      
      .gray {
        background-color: rgba(158, 158, 158, 0.2); /* Gris clair */
        color: rgba(158, 158, 158, 0.8); /* Gris */
      }
      
      .indigo {
        background-color: rgba(63, 81, 181, 0.2); /* Indigo clair */
        color: rgba(63, 81, 181, 0.8); /* Indigo */
      }
      
      .lime {
        background-color: rgba(205, 220, 57, 0.2); /* Citron vert clair */
        color: rgba(205, 220, 57, 0.8); /* Citron vert */
      }