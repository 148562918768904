.main{
	position: absolute;
	top: 61px;
	left: 285px;
	bottom: 0px;
	right: 351px;
	padding-left: 30px;
	padding-right: 30px;
    text-align: left;
}


.select-asset{
    margin-top: 17px;
    display: inline-block;
    height: 44px;
    padding-right: 50px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-left: -10px;
	text-align: left;
    z-index: 2;
}

.h1-select-title{
    font-size: 16px;
    font-weight: 500;
    padding: 0px;
    margin-bottom: 2px;
    margin-top: 0px;
    margin-left: 0px;
}

.h1-select-title span{
	display: none;
	color: #0e8b6e;
	font-weight: 400;
	font-size: 15px;
	margin-left: 5px;
}

.description-select-title{
	color: #93a1ca;
    font-size: 15px;
    font-weight: 300;
    float: left;
	
}

.asset-chip2 {
    font-size: 7px;
    display: block;
    margin-top: 1px;
    height: fit-content;
    line-height: normal;
    text-align: center;
    width: fit-content;
    font-weight: 500;
    text-transform: uppercase;
    float: left;
    margin-left: 10px;
    border-radius: 2px;
    padding: 4px 8px 4px 8px;
}

.asset-chip-color1{
	background: #9b9b9b29;
	color: #9b9b9b;
}

.material-symbols-outlined {
	font-variation-settings:
	'FILL' 0,
	'wght' 400,
	'GRAD' 0,
	'opsz' 24
  }

  .flepl {
    position: absolute;
    right: 15px;
    top: 17px;
    height: 64px;
    line-height: 64px !important;
    display: none !important;
}

.buy{
	display: block;
    width: 74px;
    height: 35px;
    border-radius: 3px;
    text-align: center;
    line-height: 35px;
    text-transform: uppercase;
    background: #0e8b6e;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    position: absolute;
    right: 30px;
    top: 30px;
	cursor: pointer;
}

.buy:hover{
	background: #0d9e7d;
}

.mainprice{
	display: block;
    width: 200px;
    height: 35px;
    text-align: right;
    line-height: 35px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 20px;
    position: absolute;
    right: 120px;
    top: 30px;
	color: #0e8b6e;
	
}

.price-right {
    right: 30px; 
  }

.chart{
	position: absolute;
    left: 30px;
    right: 30px;
    top: 100px;
	bottom: 338px;
}
.chart-top{
	position: absolute;
    top: 0px;
    bottom: 30px;
    left: 0px;
    right: 0px;
	
}

.chart-value-3{
	
	color: #93a1ca;
    font-size: 10px;
    font-weight: 300;
	position: absolute;
	left: 0px;
	top: 0px;
	border-bottom: 1px solid #0000000a;
    padding-bottom: 10px;
	width: 100%;
	text-align: left;
}

.chart-value-2{
	
	color: #93a1ca;
    font-size: 10px;
    font-weight: 300;
	position: absolute;
	left: 0px;
	top: calc(50% - 12px);
	border-bottom: 1px solid #0000000a;
    padding-bottom: 10px;
	width: 100%;
	text-align: left;
}

.chart-value-1{
	
	color: #93a1ca;
    font-size: 10px;
    font-weight: 300;
	position: absolute;
	left: 0px;
	bottom: 12px;
	
}

.chart-bottom{
	height: 30px;
    position: absolute;
    left: 0px;
    right: 0px;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: right;
    color: #93a1ca;
    font-size: 10px;
    font-weight: 300;
    line-height: 30px;
	bottom: 0px;
	border-top: 1px solid #0000000a;
}
.chart-bottom span{
	margin-top: 8px;
}

.widgets{
	position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
	height: 278px;
}

.widget-tiers{
	position: relative;
	float: left;
	display: block;
	width: calc((100% / 3) - (20px / 3));
	height: calc((100% / 2) - (10px / 2));
	background: #94a2cb0a;
}

.wid-margin-right{
	margin-right: 10px;
}

.wid-margin-bottom {
    margin-bottom: 10px;
}
  
.widgets-title{
	font-size: 14px;
    font-weight: 500;
    display: block;
	margin-top: 30px;
	text-align: center;
}

.tit1wid{
	font-size: 15px;
	margin-top: 18px;
	color: #93a1ca;
	text-align: center;
}

.blck-color{
	color: #000;
}

.minfo{
	color: #93a1ca;
	text-decoration: underline;
	font-size: 13px;
	font-weight: 300;
	display: block;
	margin-top: 6px;
	text-align: center;
	cursor: pointer;
	
}
.minfo:hover{
	color: #294598;
}

.widtrend{
	display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
	margin-top: 15px;
}



.notetext{
	color: #000;
    font-size: 18px !important;
    vertical-align: super;
    margin-left: 10px !important;
    font-weight: 500;
}
.notetext2{
    color: #000;
    font-size: 18px !important;
    font-weight: 500;
    position: absolute;
    right: 32px;
    bottom: 28px;
}

.barwid{
    bottom: 36px;
    height: 3px;
    left: 30px;
    right: 70px;
	position: absolute;
	background: rgb(148,162,203);
    background: linear-gradient(138deg, rgba(148,162,203,1) 0%, rgba(41,69,152,1) 100%);
	
}

.barwinprogress{
	position: absolute;
    background: #c6c7c8;
    height: 100%;
    right: 0px;
}

.graph-container {
    width: 100%;
    height: 100%; /* S'assure que le conteneur prend toute la hauteur disponible */
  }


  /* DROPDOWN */

  .dropDownElements{
    position: absolute;
    width: 280px;
    background-color: rgb(245, 246, 250);
    height: 300px;
    top: 60px;
    overflow: auto;
    padding: 5px;
    /* right: 5px; */
  }

  .asset-ti2 {
    font-size: 14px;
    font-weight: 500;
    /* text-indent: 30px; */
    display: block;
    text-align: left;
}

.asset-de2 {
    font-size: 13px;
    font-weight: 300;
    /* text-indent: 30px; */
    display: block;
    color: #93a1ca;
    margin-top: 2px;
}

.asset-cnt-price2 {
    height: 26px;
    /* padding-left: 30px; */
    padding-right: 30px;
}