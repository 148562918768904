.sidebar-right{
	position: absolute;
	top: 61px;
	background: #ffffff91;
	width: 350px;
	bottom: 0px;
	right: 0px;
	border-left: 1px solid #e9e9e9;
	
}

.title-section{
	position: relative;
	height: 74px;
	line-height: 74px;
	padding-left: 30px;
	overflow: hidden;
	
}

.titlesongon{
	font-size: 16px;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
    display: block;
    float: left;
    margin-right: 20px;
	cursor: pointer;
	color: #000;
}
.titlesongon.active{
	font-size: 16px;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
    display: block;
    float: left;
    margin-right: 20px;
	text-decoration: underline;
	cursor: pointer;
	color: #94a2cb;
}

.titlesongoff{
	font-size: 16px;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
    display: block;
    float: left;
    margin-right: 20px;
	color: #000;
	cursor: pointer;
}
.titlesongoff:hover{
	color: #93a2ca;
}

.chipnot{
	font-size: 8px;
	font-weight: 500;
	color: #fff;
	background: #df525e;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 2px;
	padding-bottom: 2px;
	border-radius: 2px;
	margin-left: 5px;
	vertical-align: bottom;
}

.safe-list{
    position: relative;
    left: 0px;
    top: 0px;
    right: 0px;
	height: 255px;
	border-bottom: 1px solid #e9e9e9;
	overflow: auto;
}

.item-safe{
	margin-left: 30px;
	margin-right: 30px;
	border-top: 1px solid #0000000a;
	position: relative;
	padding-top: 20px;
	padding-bottom: 20px;
	cursor: pointer;
	
}

.item-safe-title{
	height: 20px;
}

.asset-ti-safe{
	font-size: 14px;
    font-weight: 500;
    display: block;
	height: 20px;
	line-height: 20px;
	float: left;
}
.asset-ti-safe span {
	font-size: 10px;
    font-weight: 500;
	vertical-align: text-top;
	color: #93a1ca;
	cursor: pointer;
	line-height: 10px;
}

.price-asset-safe{
	font-size: 13px;
    font-weight: 300;
    display: block;
    color: #000;
	float: right;
	height: 20px;
	line-height: 20px;
}

.item-safe-details{
	height: 15px;
	margin-top: 5px;
}

.action{
	color: #93a1ca;
    text-decoration: underline;
    font-size: 13px;
    font-weight: 300;
    display: block;
	float: left;
	line-height: 16px;
	height: 16px;
	margin-right: 4%;
	cursor: pointer;
}
.action:hover{
	color: #294598;
}

.icoresize{
	font-size: 13px !important;
	color: #93a1ca;
	vertical-align: sub;
	margin-right: 2px;
}

.offerssub{
	font-size: 10px;
	color: #df525e;
	font-weight: 500;
	vertical-align: text-top;
	line-height: 10px;
	float: left;
	margin-left: -4%;
	padding-left: 2px;
}

.offer-list{
	position: absolute;
    overflow: auto;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 404px;
}

.item-offer{
	margin-left: 30px;
	margin-right: 30px;
	border-top: 1px solid #0000000a;
	position: relative;
	padding-top: 20px;
	padding-bottom: 20px;
	cursor: pointer;
	
}

.item-offer-title{
	height: 20px;
}

.asset-ti-offer{
	font-size: 14px;
    font-weight: 500;
    display: block;
	height: 20px;
	line-height: 20px;
	float: left;
}
.asset-ti-offer span {
	font-size: 10px;
    font-weight: 500;
	vertical-align: text-top;
	color: #93a1ca;
	cursor: pointer;
	line-height: 10px;
}

.price-asset-offer{
	font-size: 13px;
    font-weight: 300;
    display: block;
    color: #000;
	float: right;
	height: 20px;
	line-height: 20px;
}

.item-offer-details{
	height: 15px;
	margin-top: 5px;	
}

.name-offer{
	color: #93a1ca;
	font-size: 13px;
	font-weight: 300;
	height: 20px;
	line-height: 20px;
	float: left;
	
}

.offer-profit{
	font-size: 13px;
    display: block;
    height: 20px;
    line-height: 20px;
    text-align: right;
    width: 22%;
    font-weight: 300;
    float: right;
    margin-left: 4%;
	color: #0e8b6e;
}

.price-offer-offer{
	font-size: 13px;
    font-weight: 300;
    display: block;
    color: #000;
	float: right;
	height: 20px;
	line-height: 20px;
	color: #0e8b6e;
}

.offer-loss {
	color: #df525e;
}

.item-actions-details{
	height: 25px;
	margin-top: 10px;
}

.chrono{
	font-size: 13px;
	height: 25px;
	line-height: 25px;
	font-weight: 400;
	display: block;
	float: left;
}

.accept{
	
	height: 25px;
	line-height: 25px;
	border-radius: 2px;
	background: #0e8b6e;
	color: #fff;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	float: right;
	padding-left: 3%;
	padding-right: 3%;
	margin-left: 6px;
	cursor: pointer;
	
}
.accept:hover{
	background: #0d9e7d;
}

.decline{
	height: 25px;
	line-height: 25px;
	border-radius: 2px;
	background: #df525e;
	color: #fff;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	float: right;
	padding-left: 3%;
	padding-right: 3%;
	cursor: pointer;
	
}
.decline:hover{
	background: #ef3d4c;
}


.messageNoNothingSidebarRight{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:  50px;
	height: 150px;
}
.messageNoNothingSidebarRight span{
    font-size: 25px;
    color: rgb(237, 237, 237);
}
.messageNoNothingSidebarRight svg{
    font-size: 100px;
    color: rgb(237, 237, 237);
}