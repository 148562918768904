.header {
	position: absolute;
	top: 0;
	width: 100%;
	height: 60px;
	border-bottom: 1px solid #e9e9e9;
  }
  
  .logo {
	height: 60px;
	margin-left: 25px;
	/* margin-top: 13px; Supprimé pour utiliser flexbox pour le centrage */
	float: left;
  }
  
  .logo img {
	max-width: 100%; /* Assurez-vous que l'image ne dépasse jamais la largeur du conteneur .logo */
	max-height: 60px; /* Hauteur maximale correspondant à celle de la barre de navigation */
	object-fit: contain; /* Maintient les proportions sans tronquer l'image */
  }

.profil{
	float: right;
	width: 30px;
	height: 30px;
	background: #93a1ca42;
	border-radius: 100%;
	margin-top: 15px;
	margin-right: 30px;
	text-align: center;
	color: #93a1ca;
	text-transform: uppercase;
	line-height: 30px;
	font-size: 13px;
	font-weight: 500;
	cursor: pointer;
}

.idendity{
	
	float: right;
	height: 30px;
	margin-top: 15px;
	margin-right: 10px;
	text-align: right;
	color: #000;
	line-height: 30px;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	
}

.menu{
	
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    margin-right: 40px;
	position: absolute;
	left: 315px;
	font-weight: 500;
}

.menu a{
	margin-right: 15px;
}

.aoff{
	color: #000;
	cursor: pointer;
	
}
.aoff:hover{
	color: #94a2cb;
	
}

.aon{
	color: #94a2cb;
	text-decoration: underline;
	
}

.menumob{
	
	height: 60px;
	line-height: 60px !important;
	float: right;
	margin-right: 30px;
	display: block !important;
	font-size: 30px !important;
	position: relative;
	cursor: pointer;
	display: none !important;
}
.menumob:hover{
	
	color: #93a1ca;
}


.clocheContainer{
    position: relative;
}

.cloche{
	
	height: 60px !important;
	line-height: 60px !important;
	float: right;
	margin-right: 30px;
	display: block !important;
	font-size: 20px !important;
	position: relative;
	cursor: pointer;
}

.cloche:hover{
	color: #93a2ca;
}

.notificationDotCloche{
    position: absolute;
    width: 5px;
        height: 5px;
        background: #df525e;
        right: 25px;
        top: 20px;
    }

    .language{
	
        height: 60px !important;
        line-height: 60px !important;
        float: right;
        margin-right: 5px;
        display: block !important;
        font-size: 20px !important;
        position: relative;
        cursor: pointer;
    }

    .language:hover{
	
        color: #93a2ca;
    
    }
    
	.userMenuNavbar {
		position: absolute;
		width: 153px;
		background-color: #ffffff;
		right: 115px;
		top: 40px;
		z-index: 9999;
		border-radius: 5px;
		border-top-left-radius: 20px;
		border-top-right-radius: 5px;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
	}

	.userMenuNavbarWrapper{
		display: flex;
		flex-direction: column;
		padding: 10px;
	}

	.userMenuNavbarItem{
		flex: 1;
		padding: 10px;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 20px;
		color: #93a1ca;
	}
	.userMenuNavbarItem{
		flex: 1;
		padding: 10px;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 20px;
		color: #93a1ca;
		font-size: 14px;
	}
	.userMenuNavbarItem svg{
		font-size: 18px;
	}
	.userMenuNavbarItem:hover{
		background-color: rgba(210, 210, 210, 0.098);
		border-radius: 5px;
	}

