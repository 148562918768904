.tablePlatformConfiguration {
    width: 100%;
    font-family: 'nunitoregular', arial;
    font-size: 11px;
    color: #a1a1a1;
    box-shadow: 0 4px 5px #F4F4F4;
}

.tablePlatformConfiguration thead, .tablePlatformConfiguration thead a {
    text-transform: uppercase;
    font-size: 12px;
    color: #bcbcbd;
}

.tablePlatformConfiguration thead {
    background: #fff;
}
.tablePlatformConfiguration thead tr td span{
   font-size: 12px;
}
.tablePlatformConfiguration td:first-child {
    width: 25%;
}

.tablePlatformConfiguration td {
    text-align: left;
}

.tablePlatformConfiguration thead td, .tablePlatformConfiguration thead th {
    height: 35px;
    padding-top: 27px;
    vertical-align: top;
}

.tablePlatformConfiguration td, .tablePlatformConfiguration th {
    padding: 6px 10px;
    vertical-align: middle;
}

.tablePlatformConfiguration tbody tr {
    height: 50px;
    color: #a1a1a1;
}

.tablePlatformConfiguration tbody tr {
    background: #fbfbfb;
}

.image-uploader-dropzone {
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 5px;
    position: relative;
    text-align: center;
    cursor: pointer !important;
}

.image-uploader.small .image-uploader-dropzone .material-icons {
    line-height: 35px;
    font-size: 25px !important;
}

.image-uploader-dropzone .material-icons {
    font-size: 125px !important;
    line-height: 225px;
    display: inline-flex;
    opacity: 0.2;
}

.image-uploader.landscape {
    width: auto;
    min-width: 100px;
    max-width: 100%;
}

.image-uploader.small {
    /* width: 50px; */
    height: 50px;
    min-width: 50px;
}

.image-uploader {
    width: 250px;
    min-width: 250px;
    height: 250px;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}

.image-uploader-image {
    position: relative; 
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease; 
}
.image-uploader-image img {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    display: block;
    transition: all 0.2s;
}

.image-uploader-image .material-icons {
    position: absolute;
    right: 5px; 
    bottom: 5px; 
    color: #e76790; 
    opacity: 0; 
    transition: opacity 0.2s ease; 
    font-size: 18px; 
}

.image-uploader-image:hover {
    background-color: rgba(171, 171, 171, 0.056); 
}

.image-uploader-image:hover img {
    opacity: 0.7; 
}

.image-uploader-image:hover .material-icons {
    opacity: 1; 
    color: #e76790; 
}