.rightElements .create-btn.disabled {
    background-color: grey;
    color: #ccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }

  .filterSectionProductTable {
    position: absolute;
    width: 230px;
    height: 180px;
    background-color: #fbfbfb;
    top: 100px;
    left: 272px;
    z-index: 9999;
    border-radius: 5px;
    overflow: auto;
    padding: 10px;
}

	.filterSectionProductTable select,
.filterSectionProductTable input[type="number"],
.filterSectionProductTable input[type="text"],
.filterSectionProductTable input[type="range"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.filterSectionProductTable label {
    margin-bottom: 5px;
    display: block;
    color: #333;
}

.filterSectionProductTable .priceRangeFilter {
    margin-top: 10px;
}

.filterSectionProductTable button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.filterSectionProductTable button:hover {
    background-color: #0056b3;
}

.leftElementsTable  button {
  padding: 6px;
  border: none;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 200;
  background-color: #ffffff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}


.leftElementsTable  button:hover{
  background-color:  var(--primary-color-hover);
  color: var(--white-text);
}