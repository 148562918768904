.salesPerfomancePageBottomLeftTopItem{
    display: flex;
    align-items: center;
    color: #c0c0c0;
    gap: 5px;
    margin-left: 10px;
    margin-top: 10px;
}
.salesPerfomancePageBottomLeftTopItem span{
    font-size: 11px;
}
.salesPerfomancePageBottomLeftTopItem svg{
    font-size: 14px;
}

.custom-tooltipPerformance{
    border-radius: 5px;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    width: 200px;
    gap: 20px;
}

.noDataMessageChart{
    position: absolute;
    top: 44%;
    left: 41%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 51px;
    color: #f1f1f1;
    display: flex;
    align-items: center;
}

.noDataMessageChart svg{
    font-size: 50px;
}