.editIconPopUpPlatforme{
    font-size: 20px;
    cursor: pointer;
}
.editIconPopUpPlatforme svg{
    font-size: 20px;
}


.editButtonPlatformPopUpInfoLead{
    width: 100%;
}

.editButtonPlatformPopUpInfoLead button{
    width: 80%;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #93a1ca;
    color: var(--white-text);
}
.editButtonPlatformPopUpInfoLead button:hover{
    background-color: #93a1cacb;
    color: var(--white-text);
}

.isEditingPopupPlateform{
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;
}